import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as dayjs from "dayjs";

import { defaultSelectOptions } from "src/app/components/default-options";
import { BaseService } from "src/app/services/base.service";
import { Product } from "src/app/models/product.model";
import { ScannedObject } from "src/app/services/scanning.service";
import { StorageService } from "src/app/services/storage.service";
import { IDefectiveReason } from "../../models/config.model";
import { ScanLogWorkflow } from "../../app.enums";
import { isPrepared } from "../../utils/inventory.util";
import { RejectRecalledProductOnReceive } from "src/app/decorators/scan-validation";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ScanMilkBankProductValidationError } from "../../decorators/scan-validation/scan-validation-error";
import { LogScannedObjectModel } from "../../models/log-scan.model";
import { ValidateBottleNumber } from "../../validations/unique-bottle-number.validator";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { SavedData } from "../../models/unreceived-product";
import { MAX_MANUFACTURER_EXPIRATION_DATE } from "src/app/app.constants";

@Component({
	selector: "app-receive-product",
	templateUrl: "./receive-product.modal.html",
	styleUrls: ["./receive-product.modal.scss"],
})
export class ReceiveProductModal {
	@Input() title: string;
	@Input() index: number;
	@Input() hasUnreadableBarcode: boolean;
	@Input() isEdit: boolean;
	@Input() savedData: SavedData;
	@Input() scannedObject: ScannedObject;
	@Input() scannedProducts: MilkBankProductModel[];

	isValidDate = true;
	isWeb: boolean;
	defaultSelectOptions = defaultSelectOptions;

	receiveProductForm: FormGroup;
	products: Product[] = [];

	excludeProductCodes = [
		"H2O",
		"EBM",
	];

	workflow: ScanLogWorkflow;

	isPrepared = isPrepared;

	onDateValidChanged(isValid: boolean): void {
		this.isValidDate = isValid;
	}

	get minDate(): dayjs.Dayjs {
		return dayjs().endOf("day")
	}

	get maxManufacturerExpirationDate(): dayjs.Dayjs {
		return MAX_MANUFACTURER_EXPIRATION_DATE;
	}

	get defectiveReasons(): IDefectiveReason[] {
		const defaultDefectiveReason = {
			reason: "None",
			discard: false,
		} as IDefectiveReason;

		if (StorageService.configs) {
			return [
				defaultDefectiveReason,
				...(StorageService.configs.defectiveReasons || []),
			];
		} 
		return [defaultDefectiveReason];
	}

	get product(): Product {
		return this.products.find((p) => p.id === this.savedData?.product.id);
	}

	get manufacturerExpirationDate() {
		if (!this.receiveProductForm) {
			return null;
		}

		return this.receiveProductForm.controls.manufacturerExpirationDate
			.value;
	}

	get invalidBottleNumber() {
		if (!this.receiveProductForm) {
			return false;
		}

		return this.receiveProductForm.get("bottleNumber").invalid;
	}

	constructor(
		private base: BaseService,
		private modalCtrl: ModalController,
		private formBuilder: FormBuilder
	) {
		this.buildForm();
		this.products = this.getManuallyReceivableProducts(StorageService.configs?.products, this.excludeProductCodes);
		this.workflow = ScanLogWorkflow.Receive_Product;
	}

	ionViewWillLeave() {
		StorageService.unsubscribeAll();
		this.workflow = null;
	}

	getManuallyReceivableProducts(products: Product[], exclude: Product["code"][]) {
		return products.filter((p) => !exclude.includes(p.code));
	}

	async buildForm() {
		this.receiveProductForm = this.formBuilder.nonNullable.group({
			product: [this.product, Validators.required],
			defectInfo: [this.defectiveReasons[0]],
			bottleNumber: [null],
			lotNumber: [this.savedData?.lotNumber, Validators.required],
			manufacturerExpirationDate: [
				this.savedData?.manufacturerExpirationDate,
				Validators.required,
			],
		});

		// If the barcode is unreadable, then Defect is required.
		if (this.hasUnreadableBarcode) {
			this.receiveProductForm.controls.defectInfo.setValidators(
				Validators.required
			);
			this.receiveProductForm.controls.defectInfo.updateValueAndValidity();
		}

		// When lot number changes, run the validation for bottle number.
		// All validation is run when any input on the form is changed.
		this.receiveProductForm.controls.lotNumber.valueChanges.subscribe(
			(lotNumber) => {
				this.receiveProductForm.controls.bottleNumber.setValidators(
					ValidateBottleNumber(this.scannedProducts, lotNumber)
				);
				this.receiveProductForm.controls.bottleNumber.updateValueAndValidity();
			}
		);
	}

	onDateChanged(date: dayjs.Dayjs) {
		this.receiveProductForm.patchValue({
			manufacturerExpirationDate: date,
		});
	}

	/**
	 * If the user does a “create label” and selects a reason that should result in discard, we do NOT want to create a
	 * label (its wasteful). We need to show them this message when they select the defect reason if it is one that the
	 * tenant has discard toggled on: “The defect you selected requires that this bottle be discarded per hospital
	 * policy. MilkTracker will not print a label for this bottle or add it to inventory.”
	 */
	setDefectiveReason(ev: any) {
		const formDefectInfo =
			this.receiveProductForm.controls.defectInfo.value;
		const reason: string = ev.target.value;
		const defectInfo = this.defectiveReasons.find(
			(r) => r.reason === reason
		);
		if (formDefectInfo.discard) {
			this.base.alerts.showDiscardDefectiveAlert();
		}

		if (formDefectInfo === defectInfo) {
			return;
		}
	}

	async handleSaveButtonPressed() {
		const { lotNumber, manufacturerExpirationDate, product, defectInfo } =
			this.receiveProductForm.value;

		this.savedData = {
			product,
			manufacturerExpirationDate,
			lotNumber,
			defectInfo
		};

		if (this.isEdit) {
			await this.modalCtrl.dismiss({
				savedData: this.savedData,
			});
		} else {
			await this.add(this.receiveProductForm.value);
		}
	}

	async handleAddButtonPressed() {
		try {
			await this.add(this.receiveProductForm.value);
		} catch (error) {
			if (error instanceof ScanMilkBankProductValidationError) {
				await this.base.loggingService.logScan(
					new LogScannedObjectModel({
						type: error.scanLogType,
						workflow: this.workflow,
						productId: this.product.id,
					})
				);
				await this.modalCtrl.dismiss();

				const { header, message, modalType } = error.modal;
				await this.base.modalService.presentOverlayModal(
					modalType,
					header,
					[message]
				);
			} else {
				console.error(error);
			}
		}
	}

	@RejectRecalledProductOnReceive()
	async add(params: {
		product: Product;
		lotNumber: string;
		bottleNumber: string;
		manufacturerExpirationDate: dayjs.Dayjs;
		defectInfo: IDefectiveReason;
	}) {
		const { ...rest } = params;	

		await this.modalCtrl.dismiss({
			index: this.index,
			savedData: this.savedData,
			submitData: {
				hasUnreadableBarcode: this.hasUnreadableBarcode,
				...rest,
			},
			scannedObject: this.scannedObject,
		});
	}

	/**
	 * Allows the entire Product object to be saved in the form
	 * while only displaying the name.
	 * https://angular.io/api/forms/SelectControlValueAccessor#customizing-option-selection
	 */
	compareProduct(p1: Product, p2: Product): boolean {
		return p1 && p2 ? p1.id === p2.id : p1 === p2;
	}

	/**
	 * Allows the entire IDefectiveReason object to be saved in the form
	 * while only displaying the reason.
	 * https://angular.io/api/forms/SelectControlValueAccessor#customizing-option-selection
	 */
	compareDefect(d1: IDefectiveReason, d2: IDefectiveReason): boolean {
		return d1 && d2 ? d1.reason === d2.reason : d1 === d2;
	}

	clearFields() {
		this.receiveProductForm?.reset();
	}

	isValid() {
		if (!this.isValidDate) {
			return false;
		}
		return this.receiveProductForm?.valid;
	}

	async cancel() {
		await this.modalCtrl.dismiss();
	}
}

import {
	Component,
	Input,
	OnInit,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
	assertExhaustive,
	FeedState,
	MilkState,
	MilkType,
	ProductState,
	ProductType,
} from '../../app.enums';
import { capitalize } from 'src/app/app.util';
import { MilkBottleModel } from '../../models/milk.model';
import { MilkBankProductModel } from '../../models/milk-bank-product.model';
import { defaultSelectOptions } from '../../components/default-options';
import dayjs, { Dayjs } from 'dayjs';
import { getExpirationDuration } from '../../utils/expiration.util';
import { getBottleNumberText } from '../../utils/milk-label.util';
import { FeedObjectModel } from '../../models/feed-object.model';
import {
	DateTypeLabel,
	InlineDateComponent,
} from '../../components/inline-date/inline-date.component';
import { StorageService } from 'src/app/services';

@Component({
	selector: 'app-edit-milk',
	templateUrl: './edit-milk.modal.html',
	styleUrls: ['./edit-milk.modal.scss'],
})
export class EditMilkModal implements OnInit {
	@ViewChildren(InlineDateComponent)
	inlineDateComponents: QueryList<InlineDateComponent>;

	@Input() hasEditableVolume = false;
	@Input() hasEditableExpendDate = false;
	@Input() hasVisibleBottleNumber = true;
	@Input() hasDisabledPresetState = false;
	@Input() title = 'edit milk data';
	@Input() header: string;
	@Input() message: string;
	@Input() doneText = 'save';

	@Input() presetState: FeedState;
	@Input() milkBottle: MilkBottleModel;
	@Input() milkBankProduct: MilkBankProductModel;

	capitalize = capitalize;
	getBottleNumberText = getBottleNumberText;

	get feed() {
		if (this.milkBottle) {
			return this.milkBottle;
		}

		if (this.milkBankProduct) {
			return this.milkBankProduct;
		}

		throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
	}

	/**
	 * There's a typing issue if the return type is FeedState.
	 * It's better to just use string because the type will be inherited anyway
	 * when assigned.
	 */
	get feedStates(): string[] {
		if (this.milkBottle) {
			return this.milkStates;
		}

		if (this.milkBankProduct) {
			return this.productStates;
		}

		throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
	}

	/**
	 * Exclude Stable because no date needs to be edited
	 * Exclude Opened because Stable feed will be opened automatically when the label is printed
	 */
	get milkStates(): MilkState[] {
		return (
			Object.entries(MilkState)
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.map(([key, value]) =>
					value !== MilkState.Stable && value !== MilkState.Opened
						? value
						: null,
				)
				.filter((s) => s)
		); // remove null
	}

	/**
	 * Exclude Stable because no date needs to be edited
	 * Exclude Opened because Stable feed will be opened automatically when the label is printed
	 */
	get productStates(): ProductState[] {
		return (
			Object.entries(ProductState)
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.map(([key, value]) =>
					value !== ProductState.Stable && value !== ProductState.Opened
						? value
						: null,
				)
				.filter((s) => s)
		); // remove null
	}

	get feedState(): FeedState {
		if (this.milkBottle) {
			return this.milkBottle.milkState;
		}

		if (this.milkBankProduct) {
			return this.milkBankProduct.productState;
		}

		throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
	}

	/**
	 * When feed state is set, reset the date
	 * TODO: is it possible to refactor to only use feedState?
	 *
	 * @param feedState
	 */
	set feedState(feedState: FeedState) {
		if (this.milkBottle) {
			this.milkBottle.feedState = this.milkBottle.milkState =
				feedState as MilkState;
		} else if (this.milkBankProduct) {
			this.milkBankProduct.feedState = this.milkBankProduct.productState =
				feedState as ProductState;
		} else {
			throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
		}
	}

	get feedType(): MilkType | ProductType {
		if (this.milkBottle) {
			return this.milkBottle.milkType;
		}

		if (this.milkBankProduct) {
			return this.milkBankProduct.productType;
		}

		throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
	}

	get minDate(): Dayjs {
		const duration = getExpirationDuration({
			feedType: this.feedType,
			feedState: this.feedState,
			isFortified: false,
			contents: [],
			expirationPolicy: StorageService.expirationPolicy,
		});
		return dayjs().subtract(duration, 'hour');
	}

	get maxDate(): Dayjs {
		return dayjs().endOf('day');
	}

	defaultSelectOptions = defaultSelectOptions;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit() {
		// set preset state
		if (this.presetState) {
			if (this.milkBottle) {
				this.milkBottle.milkState = this.presetState as MilkState;
			} else if (this.milkBankProduct) {
				this.milkBankProduct.productState = this.presetState as ProductState;
			} else {
				throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
			}
		}
	}

	async back() {
		await this.modalCtrl.dismiss();
	}

	/**
	 * Programmatically click "done" for each datetime component.
	 */
	async confirmDatePickerSelection() {
		this.inlineDateComponents.forEach((inlineDateComponent) =>
			inlineDateComponent.done(),
		);
	}

	async submit() {
		await this.confirmDatePickerSelection();

		await this.modalCtrl.dismiss({
			milkBottle: this.milkBottle,
			milkBankProduct: this.milkBankProduct,
		} as EditMilkModalOnDismiss);
	}

	/**
	 * Frozen and Fresh need pump date editable
	 * Thawed needs thaw date editable
	 */
	getFeedStateDateText(): DateTypeLabel {
		switch (this.feedState) {
			case MilkState.Fresh:
			case MilkState.Frozen:
			case ProductState.Frozen:
				return 'Pump Date';
			case MilkState.Thawed:
			case ProductState.Thawed:
				return 'Thaw Date';
			case MilkState.Stable:
			case ProductState.Stable:
			case MilkState.Opened:
			case ProductState.Opened:
				return '';
			default:
				assertExhaustive(this.feedState);
				throw Error('unknown state');
		}
	}

	getFeedStateDate(feedObject: FeedObjectModel): dayjs.Dayjs {
		if (feedObject instanceof MilkBottleModel) {
			switch (feedObject.milkState) {
				case MilkState.Fresh:
				case MilkState.Frozen:
					return feedObject.pumpDate ? dayjs(feedObject.pumpDate) : null;
				case MilkState.Opened:
					return feedObject.openedDate ? dayjs(feedObject.openedDate) : null;
				case MilkState.Stable:
					return;
				case MilkState.Thawed:
					return feedObject.thawedDate ? dayjs(feedObject.thawedDate) : null;
				default:
					assertExhaustive(feedObject.milkState);
					throw Error('unknown state');
			}
		}

		if (feedObject instanceof MilkBankProductModel) {
			switch (feedObject.productState) {
				case ProductState.Thawed:
					return feedObject.thawedDate ? dayjs(feedObject.thawedDate) : null;
				case ProductState.Opened:
					return feedObject.openedDate ? dayjs(feedObject.openedDate) : null;
				case ProductState.Stable:
				case ProductState.Frozen:
					return;
				default:
					assertExhaustive(feedObject.productState);
					throw Error('unknown state');
			}
		}

		throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
	}

	/**
	 * Open or Stable feeds should not be editable/settable.
	 */
	hasEditableStateDate(feed: MilkBottleModel | MilkBankProductModel) {
		if (feed instanceof MilkBottleModel) {
			return (
				feed.milkState !== MilkState.Opened &&
				feed.milkState !== MilkState.Stable
			);
		}

		if (feed instanceof MilkBankProductModel) {
			return (
				feed.productState !== ProductState.Opened &&
				feed.productState !== ProductState.Stable
			);
		}

		return false;
	}

	/**
	 * @deprecated
	 */
	hasStateDate(feed: {
		milkBottle?: MilkBottleModel;
		milkBankProduct?: MilkBankProductModel;
	}) {
		if (feed.milkBottle) {
			return (
				feed.milkBottle.milkState !== MilkState.Opened &&
				feed.milkBottle.milkState !== MilkState.Stable
			);
		}

		if (feed.milkBankProduct) {
			return (
				feed.milkBankProduct.productState !== ProductState.Opened &&
				feed.milkBankProduct.productState !== ProductState.Stable
			);
		}

		return false;
	}

	setDates2(feed: MilkBottleModel | MilkBankProductModel, date: dayjs.Dayjs) {
		if (feed instanceof MilkBottleModel) {
			switch (this.milkBottle.milkState) {
				case MilkState.Fresh:
				case MilkState.Frozen:
					this.milkBottle.pumpDate = date;
					this.milkBottle.thawedDate = null;
					break;
				case MilkState.Opened:
				case MilkState.Stable:
					this.milkBottle.pumpDate = null;
					this.milkBottle.thawedDate = null;
					break;
				case MilkState.Thawed:
					this.milkBottle.pumpDate = null;
					this.milkBottle.thawedDate = date;
					break;
				default:
					assertExhaustive(this.milkBottle.milkState);
					throw Error('unknown state');
			}
		} else if (feed instanceof MilkBankProductModel) {
			switch (this.milkBankProduct.productState) {
				case ProductState.Frozen:
					this.milkBankProduct.thawedDate = null;
					break;
				case ProductState.Thawed:
					this.milkBankProduct.thawedDate = date;
					break;
				case ProductState.Stable:
				case ProductState.Opened:
					break;
				default:
					assertExhaustive(this.milkBankProduct.productState);
					throw Error('unknown state');
			}
		} else {
			throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
		}
	}

	setDates(
		feed: {
			milkBottle?: MilkBottleModel;
			milkBankProduct?: MilkBankProductModel;
		},
		date: dayjs.Dayjs,
	) {
		if (feed.milkBottle) {
			switch (this.milkBottle.milkState) {
				case MilkState.Fresh:
				case MilkState.Frozen:
					this.milkBottle.pumpDate = date;
					this.milkBottle.thawedDate = null;
					break;
				case MilkState.Opened:
				case MilkState.Stable:
					this.milkBottle.pumpDate = null;
					this.milkBottle.thawedDate = null;
					break;
				case MilkState.Thawed:
					this.milkBottle.pumpDate = null;
					this.milkBottle.thawedDate = date;
					break;
				default:
					assertExhaustive(this.milkBottle.milkState);
					throw Error('unknown state');
			}
		} else if (feed.milkBankProduct) {
			switch (this.milkBankProduct.productState) {
				case ProductState.Frozen:
					this.milkBankProduct.thawedDate = null;
					break;
				case ProductState.Thawed:
					this.milkBankProduct.thawedDate = date;
					break;
				case ProductState.Stable:
				case ProductState.Opened:
					break;
				default:
					assertExhaustive(this.milkBankProduct.productState);
					throw Error('unknown state');
			}
		} else {
			throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
		}
	}

	setVolume(milkBottle: MilkBottleModel, volume: string) {
		milkBottle.volume = Number.parseInt(volume);
	}

	isValid(feed: {
		milkBottle?: MilkBottleModel;
		milkBankProduct?: MilkBankProductModel;
	}): boolean {
		if (feed.milkBottle) {
			switch (feed.milkBottle.milkState) {
				case MilkState.Fresh:
				case MilkState.Frozen:
					return !!feed.milkBottle.pumpDate && !feed.milkBottle.thawedDate;
				case MilkState.Opened:
				case MilkState.Stable:
					return !feed.milkBottle.pumpDate && !feed.milkBottle.thawedDate;
				case MilkState.Thawed:
					return !feed.milkBottle.pumpDate && !!feed.milkBottle.thawedDate;
				default:
					assertExhaustive(feed.milkBottle.milkState);
					throw Error('unknown state');
			}
		}

		if (feed.milkBankProduct) {
			switch (this.milkBankProduct.productState) {
				case ProductState.Frozen:
					return !feed.milkBankProduct.thawedDate;
				case ProductState.Thawed:
					return !!feed.milkBankProduct.thawedDate;
				case ProductState.Stable:
				case ProductState.Opened:
					break;
				default:
					assertExhaustive(this.milkBankProduct.productState);
					throw Error('unknown state');
			}
		}

		throw Error('ngOnInit: milkBottle xor milkBankProduct not found.');
	}
}

export interface EditMilkModalOnDismiss {
	milkBottle: MilkBottleModel;
	milkBankProduct: MilkBankProductModel;
}

export interface EditMilkModalHasDismissed {
	data: EditMilkModalOnDismiss;
}

import packageInfo from "../../package.json";
import * as _ from "lodash";
import { IPermissions } from "../app/models/user-profile.model";
import { IFeatureFlag } from "../app/features.enums";

export interface Environment {
	production?: boolean;
	type?: string;
	baseUrl: BaseUrl;
	apiUrl?: string;
	portalUrl?: string;
	name?: string;
	version?: string;
	settings?: {
		sessionLengthMinutes?: number;
		timeoutDuration?: number;
		enableBadgeScanning?: boolean;
		enableSelectMilk?: boolean;
		authGuard?: boolean;
		bypassExpiration?: boolean;
		bypassOrderMatching?: boolean;
		showMobileDateTimePicker?: boolean;
		overridePermissions?: IPermissions; // lets developers override permissions
		overrideFeatureFlags?: IFeatureFlag; // lets developers override feature flags
		apiDelay?: number; // artificial delay on api calls
		removeAllOrders?: boolean;
		clerkSignInURL?: string;
		clerkSignOutURL?: string;
	};
}

// Enforce type to prevent invalid values
type WithPrefix<T extends string> = `${T}${string}`;
export type BaseUrl = WithPrefix<"http://" | "https://" | "unset">;

const defaultEnvironment: Environment = {
	production: true,
	type: "tenant",
	baseUrl: "unset",
	apiUrl: "unset",
	portalUrl: "unset",
	name: packageInfo.name,
	version: packageInfo.version,
	settings: {
		sessionLengthMinutes: 20,
		timeoutDuration: 20000,
		enableBadgeScanning: false,
		enableSelectMilk: false,
		authGuard: true,
		bypassExpiration: false,
		bypassOrderMatching: false,
		showMobileDateTimePicker: false,
		apiDelay: 0,
		overridePermissions: null,
		overrideFeatureFlags: null, // lets developers override feature flags
		removeAllOrders: false,
	},
};

/** Override the default environment configuration. Overrides are applied recursively.
 * Defaults to production: true, type: "tenant", and no override permissions
 */
export function defineEnvironment(environment: Environment): Environment {
	// recursively merge
	return _.merge({}, defaultEnvironment, environment);
}

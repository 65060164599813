import { IConfigs } from './config.model';

/**
 * Tenants can manage if "Other" is displayed in the list of Override Reasons
 * in Feed Patient > Manual Entry
 */
export class OverrideReasons {
	private reasons: string[];

	static OTHER = 'Other';

	constructor(config: IConfigs) {
		this.reasons = this.initialize(config);
	}

	private initialize(config: IConfigs) {
		const overrideReasons = config.overrideReasons;
		const isAllowManualEntryFreeText = config.tenant.allowManualEntryFreeText;

		return [
			...overrideReasons,
			...(isAllowManualEntryFreeText &&
			!overrideReasons.includes(OverrideReasons.OTHER)
				? [OverrideReasons.OTHER]
				: []),
		];
	}

	getOverrideReasons(): string[] {
		return [...this.reasons];
	}
}

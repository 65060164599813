<ion-app>
	<ion-modal style="display: none;"></ion-modal>
	<ion-split-pane contentId="main-content">
		<ion-menu
			[disabled]="!StorageService.authToken || (StorageService.isWeb && !StorageService.isMenuOpen)"
			contentId="main-content"
			type="overlay"
			side="end"
			[swipeGesture]="StorageService.authToken">
			<ion-content>
				<div class="menu">
					<div class="menu--top">
						<!-- User -->
						<ion-item
							class="menu__header"
							detail="false"
							lines="none"
							*ngIf="user"
							>
						<fa-icon [icon]="faUser" [fixedWidth]="true" size="lg" slot="start"
							class="menu__header__icon is-dark-mode"></fa-icon>
							<ion-label>
								<div class="menu__header__label">{{ user.name }}</div>
							</ion-label>
						</ion-item>


						<!-- Tenant -->
						<ion-item
							class="menu__item"
							[button]="true"
							detail="false"
							lines="none"
							(click)="selectTenant()"
							[disabled]="user?.authorizedTenants.length === 1"
							*ngIf="tenant && authorizedTenants"
							data-testid="menu__tenant_selector"
							>
							<fa-icon [icon]="faHospital" [fixedWidth]="true" size="xl" slot="start"
								class="menu__item__icon"></fa-icon>
							<ion-label>
								<div class="menu__item__label">Tenant</div>
								<ion-text class="menu__item__value">{{ getTenantLabel(tenant, authorizedTenants) }}</ion-text>
							</ion-label>
						</ion-item>

						<!-- Home Unit -->
						<ion-item
							class="menu__item"
							[button]="true"
							detail="false"
							lines="none"
							(click)="selectPreferredTenant()"
							>
							<fa-icon [icon]="faHospitalUser" [fixedWidth]="true" size="xl" slot="start"
								class="menu__item__icon"></fa-icon>
							<ion-label>
								<div class="menu__item__label">Home Unit</div>
								<ion-text class="menu__item__value" data-testid="menu__item__value_home_unit">{{ getPreferredTenantLabel(preferredTenant, authorizedTenants) }}</ion-text>
							</ion-label>
						</ion-item>

						<!-- Current Printer -->
						<ion-item
							class="menu__item"
							[button]="true"
							detail="false"
							lines="none"
							data-testid="menu__item__current_printer"
							(click)="handleSelectPrinterPressed()"
							>
							<fa-icon [icon]="faPrint" [fixedWidth]="true" size="xl" slot="start"
								class="menu__item__icon"></fa-icon>
							<ion-label>
								<div class="menu__item__label">Current Printer</div>
								<ion-text class="menu__item__value" data-testid="menu__item__value_current_printer">{{ printer?.name || "None" }}</ion-text>
							</ion-label>
						</ion-item>

						<!-- Internal Tools -->
						<ion-item
							class="menu__item"
							[button]="true"
							detail="false"
							lines="none"
							data-testid="menu__item__tools"
							*ngIf="StorageService.permissions?.global_InternalTools"
							[routerLink]="['/internal-tools']">
							<fa-icon [icon]="faScrewdriverWrench" [fixedWidth]="true" size="xl" slot="start"
								class="menu__item__icon"></fa-icon>
							<ion-label>
								<div class="menu__item__label">Tools</div>
								<ion-text class="menu__item__value">Internal Test Tool</ion-text>
							</ion-label>
						</ion-item>
					</div>

					<div class="menu--bottom">
						<ion-item
							class="menu__item"
							[button]="true"
							detail="false"
							lines="none"
							data-testid="menu__item__admin_portal"
							*ngIf="StorageService.permissions?.milkTracker_Admin_Portal"
							(click)="goToAdminPortal()">
							<fa-icon [icon]="faDungeon" [fixedWidth]="true" size="xl" slot="start"
								class="menu__item__icon"></fa-icon>
							<ion-label>
								<div class="menu__item__label">Admin Portal</div>
								<ion-text class="menu__item__value"></ion-text>
							</ion-label>
						</ion-item>

						<ion-item
							class="menu__item"
							[button]="true"
							detail="false"
							lines="none"
							data-testid="menu__item__log_out"
							(click)="logout()">
							<fa-icon [icon]="faRightFromBracket" [fixedWidth]="true" size="xl" slot="start"
								class="menu__item__icon"></fa-icon>
							<ion-label>
								<div class="menu__item__label">Log Out</div>
								<ion-text class="menu__item__value"></ion-text>
							</ion-label>
						</ion-item>
					</div>
					<app-footer [isInSideMenu]="true"></app-footer>
				</div>
			</ion-content>
		</ion-menu>
		<ion-router-outlet id="main-content"></ion-router-outlet>
	</ion-split-pane>
</ion-app>

import * as dayjs from 'dayjs';
import { IContent, MilkBottleModel } from '../models/milk.model';
import { RecipeData } from '../models/recipe.model';
import { getContentsFromRecipe } from './milk-label.util';
import { containsHumanMilk, convertYYtoYYYY } from '../app.util';
import {
	FeedState,
	MilkState,
	MilkType,
	ProductState,
	ProductType,
} from '../app.enums';
import { MilkBankProductModel } from '../models/milk-bank-product.model';
import { FeedObjectModel } from '../models/feed-object.model';
import { DATE_FORMATS } from '../components/inline-date/utils/date-time-text.util';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ExpirationPolicy } from '../models/tenant-configs.model';
import { StorageService } from '../services';

dayjs.extend(customParseFormat);

/**
 * Parses a Julian date string and returns a Dayjs object representing the date and time.
 *
 * @param julianDate - The Julian date string to parse. The format is expected to be:
 *                     - 1 character for the century (e.g., '2' for 2000-2099)
 *                     - 2 characters for the year within the century (e.g., '21' for 2021)
 *                     - 3 characters for the day of the year (e.g., '001' for January 1st)
 *                     - 2 characters for the hour (e.g., '14' for 2 PM)
 *                     - 2 characters for the minute (e.g., '30' for 30 minutes past the hour)
 * @returns A Dayjs object representing the parsed date and time.
 */
export const parseJulianDate = (julianDate: string): dayjs.Dayjs | null => {
	if (julianDate.length !== 10) {
		console.error('Invalid Julian date format: must be 10 characters');
		return null;
	}

	if (!/^\d+$/.test(julianDate)) {
		console.error(
			'Invalid Julian date format: must contain only numeric characters.',
		);
		return null;
	}

	const century = Number.parseInt(julianDate.substring(0, 1));
	const year =
		2000 + century * 100 + Number.parseInt(julianDate.substring(1, 3));
	const dayOfYear = Number.parseInt(julianDate.substring(3, 6));
	const hour = Number.parseInt(julianDate.substring(6, 8));
	const minute = Number.parseInt(julianDate.substring(8, 10));

	if (dayOfYear < 1 || dayOfYear > 366) {
		console.error('Invalid day of year: must be between 1 and 366');
		return null;
	}

	if (hour < 0 || hour > 23) {
		console.error('Invalid hour: must be between 0 and 23');
		return null;
	}

	if (minute < 0 || minute > 59) {
		console.error('Invalid minute: must be between 0 and 59');
		return null;
	}

	// Create a dayjs object for January 1st of the year and add the day of the year minus 1
	const date = dayjs(`${year}-01-01`, 'YYYY-MM-DD').add(dayOfYear - 1, 'day');

	// Add the hours and minutes
	const finalDate = date.hour(hour).minute(minute);

	return finalDate;
};

export const getSoonestExpirationDate2 = (
	feed: FeedObjectModel[],
): dayjs.Dayjs =>
	feed
		.map((f) => f.expirationDate)
		.reduce((pre, cur) => (pre.isAfter(cur) ? cur : pre));

// TODO: this is a duplicate of getSoonestExpirationDate2
export const getSoonestExpiringMilk = (
	milkBottles: MilkBottleModel[],
): MilkBottleModel =>
	milkBottles.reduce((pre, cur) =>
		pre.expirationDate.isAfter(cur.expirationDate) ? cur : pre,
	);

/**
 * Get the expiration date based on start date (pump date, created date, bottled date, etc.)
 */
export const getExpirationDate = (
	startDate: dayjs.Dayjs,
	milkObject: {
		milkBottle?: MilkBottleModel;
		milkBankProduct?: MilkBankProductModel;
	},
): dayjs.Dayjs => {
	if (!startDate) {
		return null;
	}

	if (milkObject.milkBottle && !milkObject.milkBankProduct) {
		const expirationDuration = getExpirationDuration({
			feedType: milkObject.milkBottle.milkType,
			feedState: milkObject.milkBottle.milkState,
			isFortified: milkObject.milkBottle.isFortified,
			contents: milkObject.milkBottle.contents,
			expirationPolicy: StorageService.expirationPolicy
		});
		return startDate.add(expirationDuration, 'hour');
	}

	if (!milkObject.milkBottle && milkObject.milkBankProduct) {
		throw new Error("this shouldn't be used anymore for milk bank products");
	}

	console.error('getExpirationDate: invalid milk object');
};

/**
 * Returns duration in hours.
 *
 * EBM:
 *	expirationHoursFrozenEbm,
 * 	expirationHoursFreshEbm,
 * 	expirationHoursFreshEbmFortifiedWithHM,
 * 	expirationHoursFreshEbmFortifiedWithoutHM,
 * 	expirationHoursThawedEbm,
 *  expirationHoursThawedEbmFortified,
 *
 * DM:
 * 	expirationHoursThawedDbm,
 * 	expirationHoursThawedDbmFortifiedWithHM,
 *  expirationHoursThawedDbmFortifiedWithoutHM
 *
 * Formula:
 *  expirationHoursFormula
 */
export const getExpirationDuration = (feedObject: {
	feedType: MilkType | ProductType;
	feedState: FeedState;
	isFortified: boolean;
	contents: IContent[];
	expirationPolicy: ExpirationPolicy;
}): number => {
	const {
		feedType,
	} = feedObject;

	switch(feedType) {
		/* Milk Bottles */
		case MilkType.DM_DM:
		case MilkType.DM_Formula:
		case MilkType.EBM_DM_Formula:
		case MilkType.EBM_DM:
		case MilkType.EBM_EMB:
		case MilkType.EBM_Formula:
			return getCombinedExpirationPolicy(feedObject);
		case MilkType.EBM:
			return getEbmExpirationPolicy(feedObject);
		case MilkType.DM:
			return getDonorMilkExpirationPolicy(feedObject);
		case MilkType.Formula:
			return getFormulaExpirationPolicy(feedObject);
		/* Products */
		case ProductType.Donor:
			return getDonorExpirationPolicy(feedObject);
		case ProductType.Additive:
			return getAdditiveExpirationPolicy(feedObject);
		default:
			console.error('getExpirationDuration: unknown expiration duration');
	}
};

export const getFreshExpirationDate = (recipeData: RecipeData): dayjs.Dayjs => {
	const contentsFromRecipe = getContentsFromRecipe(recipeData);
	const duration = getExpirationDuration({
		feedType: MilkType.EBM,
		feedState: MilkState.Fresh,
		isFortified: !!contentsFromRecipe.length,
		contents: [...contentsFromRecipe],
		expirationPolicy: StorageService.expirationPolicy
	});
	return dayjs().add(duration, 'hour');
};

/**
 * FIXME: because returning dayjs() uses a dynamic date, when invoking this function multiple times, "now" will
 *  change slightly.
 */
export const getFortifiedExpirationDate = (
	milkBottle: MilkBottleModel,
	recipeData: RecipeData,
): dayjs.Dayjs => {
	const contentsFromRecipe = getContentsFromRecipe(recipeData);
	const duration = getExpirationDuration({
		feedType: milkBottle.milkType,
		feedState: milkBottle.milkState,
		isFortified: !!contentsFromRecipe.length,
		contents: [...contentsFromRecipe, ...milkBottle.contents],
		expirationPolicy: StorageService.expirationPolicy
	});
	return dayjs().add(duration, 'hour');
};

/**
 * This is currently intended for non-fortified milk
 */
export const getUnfortifiedExpirationDate = (
	milk: MilkBottleModel,
): dayjs.Dayjs => {
	const duration = getExpirationDuration({
		feedType: milk.milkType,
		feedState: milk.milkState,
		isFortified: false,
		contents: [...milk.contents],
		expirationPolicy: StorageService.expirationPolicy
	});
	return dayjs().add(duration, 'hour');
};

/**
 * Displays relative expiration
 *
 * Examples:
 * Expired 2d ago
 * Expired 5h ago
 * Expires in 3d
 * Expires in 4h
 * No Expiration
 */
export const getRelativeExpirationTime = (date: dayjs.Dayjs): string => {
	if (!date) {
		return 'No Expiration';
	}

	const diff = dayjs().diff(date, 'hour');
	if (diff > 0) {
		if (diff > 72) {
			return `Expired ${Math.floor(diff / 24)}d ago`;
		}
		return `Expired ${diff}h ago`;
	}
		const absDiff = Math.abs(diff);

		if (absDiff > 72) {
			return `Expires in ${Math.floor(absDiff / 24)}d`;
		}

		return `Expires in ${absDiff}h`;
};

/**
 * Basic check if expiration date comes after today and return a boolean value.
 */
export const isExpired = (date: dayjs.Dayjs): boolean => {
	if (!date) {
		return false;
	}
	const today = dayjs();
	const expiration = dayjs(date, 'MMMM D, YYYY');
	return today.isAfter(expiration);
};

/**
 * Check all scanned milk to determine their threshold.
 * If a milk has an expiration date that is sooner than the calculated fortified expiration date, mark it red.
 * If all milk have expiration dates that are sooner than the calculated fortified expiration date, don't mark it.
 * If all milk have expiration dates that are after the calculated fortified expiration date, don't mark it.
 */
export const isSoonerExpiration = (
	milkBottle: MilkBottleModel,
	milkBottles: MilkBottleModel[],
	recipeData: RecipeData,
): boolean => {
	const fortified = getFortifiedExpirationDate(milkBottle, recipeData);

	const thresholds = [];
	for (const m of milkBottles) {
		thresholds.push({
			isSoonerExpiration: m.expirationDate.isBefore(fortified),
			id: m.id,
		});
	}

	const allBelow =
		thresholds.length === thresholds.filter((t) => t.isSoonerExpiration).length;
	const allAbove =
		thresholds.length ===
		thresholds.filter((t) => !t.isSoonerExpiration).length;
	if (allBelow || allAbove) {
		return false;
	}

	return thresholds.find((t) => milkBottle.id === t.id).isSoonerExpiration;
};

export const handleExpirationDateFromScanGroups = (
	matchGroups: any,
): dayjs.Dayjs | undefined => {
	const expirationDateUCHMB = matchGroups?.expirationDateUCHMB;
	const expirationDate = matchGroups?.expirationDate;

	if (expirationDateUCHMB) {
		return parseJulianDate(expirationDateUCHMB);
	}

	if (expirationDate) {
		return getScannedExpirationDate(expirationDate);
	}

	console.error('No expiration date found in groups');
	return;
};

/**
 * Parses MMDDYYHHmm or YYMMDD from scanning milk labels and returns dayjs object. If time is
 * missing, set to current time.
 *
 * @param dateString
 *
 * https://day.js.org/docs/en/get-set/month
 * https://day.js.org/docs/en/get-set/date
 */
export const getScannedExpirationDate = (dateString: string): dayjs.Dayjs => {
	if (!dateString) {
		return;
	}

	let date: dayjs.Dayjs;
	if (dateString.length === 10) {
		const month = Number.parseInt(dateString.substr(0, 2));
		const day = Number.parseInt(dateString.substr(2, 2));
		const year = Number.parseInt(dateString.substr(4, 2));
		const hour = Number.parseInt(dateString.substr(6, 2));
		const minute = Number.parseInt(dateString.substr(8, 2));
		date = dayjs()
			.set('month', month - 1)
			.set('date', day)
			.set('year', convertYYtoYYYY(year))
			.set('hour', hour)
			.set('minute', minute);
		return date;
	}

	if (dateString.length === 6) {
		const year = Number.parseInt(dateString.substr(0, 2));
		const month = Number.parseInt(dateString.substr(2, 2));
		const day = Number.parseInt(dateString.substr(4, 2));
		const hour = dayjs().hour();
		const minute = dayjs().minute();
		date = dayjs()
			.set('month', month - 1)
			.set('date', day)
			.set('year', convertYYtoYYYY(year))
			.set('hour', hour)
			.set('minute', minute);
		return date;
	}

	throw Error('getScannedExpirationDate: parsing failed');
};

export const getEarliestDayjs = <T>(
	arr: T[],
	iteratee: (item: T) => dayjs.Dayjs | null | undefined,
) => {
	const dates = arr.map(iteratee).filter((v) => v != null);
	return dates.length
		? dates.reduce((pre, cur) => (pre.isAfter(cur) ? cur : pre))
		: null;
};

export const expirationDateChipColor = (
	expirationDate?: dayjs.Dayjs,
): string | undefined => {
	if (!expirationDate) {
		return undefined;
	}
	return isExpired(expirationDate) ? 'danger' : undefined;
};

export const getFormattedExpirationDate = (date, policyHours) =>
	dayjs(date).add(policyHours, 'hours').format(DATE_FORMATS.FULL);

const getDonorMilkExpirationPolicy = (feedObject: {
	feedType: MilkType | ProductType;
	feedState: FeedState;
	isFortified: boolean;
	contents: IContent[];
	expirationPolicy: ExpirationPolicy;
}): number => {
	const {
		feedState,
		isFortified,
		contents,
		expirationPolicy,
	} = feedObject;

	const {
		thawedDbm,
		thawedDbmFortifiedWithHM,
		thawedDbmFortifiedWithoutHM,
	} = expirationPolicy;

	switch(feedState) {
		case MilkState.Thawed:
		case MilkState.Opened:
			if (isFortified && containsHumanMilk(contents)) {
				return thawedDbmFortifiedWithHM;
			}

			if (isFortified && !containsHumanMilk(contents)) {
				return thawedDbmFortifiedWithoutHM;
			}

			return thawedDbm;
		case MilkState.Stable:
			// do nothing
			break;
		default:
			console.error(
				'getExpirationDuration: unknown expiration duration for DM',
			);
	}
}

const getFormulaExpirationPolicy = (feedObject: {
	expirationPolicy: ExpirationPolicy;
}): number => {
	const {
		expirationPolicy,
	} = feedObject;

	const {
		formula
	} = expirationPolicy;

	return formula;
}

const getAdditiveExpirationPolicy = (feedObject: {
	feedState: FeedState;
	expirationPolicy: ExpirationPolicy;
}): number => {
	const {
		feedState,
		expirationPolicy,
	} = feedObject;

	const {
		thawedEbm,
		frozenEbm,
	} = expirationPolicy;

	switch (feedState) {
		case ProductState.Frozen:
			// TODO: there is currently no expiration policy for frozen additive?
			return frozenEbm;
		case ProductState.Thawed:
			// TODO: there is currently no expiration policy for thawed additive?
			return thawedEbm;
		case ProductState.Opened:
		case ProductState.Stable:
			break;
		default:
			throw Error(`unknown ProductState ${feedState}`);
	}
}

const getDonorExpirationPolicy = (feedObject: {
	feedState: FeedState;
	expirationPolicy: ExpirationPolicy;
}): number => {
	const {
		feedState,
		expirationPolicy,
	} = feedObject;

	const {
		thawedDbmFortifiedWithoutHM,
	} = expirationPolicy;

	if (feedState === ProductState.Thawed) {
		return thawedDbmFortifiedWithoutHM;
	}

	console.error(
		'getExpirationDuration: unknown expiration duration for Donor',
	);
}

const getEbmExpirationPolicy = (feedObject: {
	feedType: MilkType | ProductType;
	feedState: FeedState;
	isFortified: boolean;
	contents: IContent[];
	expirationPolicy: ExpirationPolicy;
}): number => {
	const {
		feedState,
		isFortified,
		contents,
		expirationPolicy,
	} = feedObject;

	const {
		freshEbm,
		freshEbmFortifiedWithHM,
		freshEbmFortifiedWithoutHM,
		thawedEbm,
		thawedEbmFortified,
		frozenEbm,
	} = expirationPolicy;

	switch(feedState) {
		case MilkState.Frozen:
			return frozenEbm;
		case MilkState.Fresh:
			if (isFortified && containsHumanMilk(contents)) {
				return freshEbmFortifiedWithHM;
			}

			if (isFortified && !containsHumanMilk(contents)) {
				return freshEbmFortifiedWithoutHM;
			}

			return freshEbm;
		case MilkState.Thawed:
			if (isFortified) {
				return thawedEbmFortified
			}

			return thawedEbm
		default:
			console.error(
				`getExpirationDuration: unknown expiration duration for ${feedState}`,
			);
	  }
}

const getCombinedExpirationPolicy = (feedObject: {
	feedType: MilkType | ProductType;
	feedState: FeedState;
	isFortified: boolean;
	contents: IContent[];
	expirationPolicy: ExpirationPolicy;
}): number => {
	return getEbmExpirationPolicy(feedObject);
}

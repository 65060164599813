import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import * as dayjs from "dayjs";
import * as _ from "lodash";

import {
	assertExhaustive,
	ContentType,
	InventoryCategory,
	MilkBottleUseType,
	MilkLocation,
	MilkState,
	PaginationAction,
} from "src/app/app.enums";

import {
	getUniquePatientIds,
	IContent,
	IMilkBottle,
	MilkBottleModel,
	PaginatedMilkBottlesResponse,
} from "src/app/models/milk.model";
import {
	IMilkBankProduct,
	MilkBankProductModel,
	PaginatedMilkBankProductResponse,
	ThawLotRequest,
} from "src/app/models/milk-bank-product.model";

import { RestApiService } from "src/app/services/rest-api.service";
import {
	MilkTrackerMilkBankProductQRCode,
	ScannedObject,
} from "./scanning.service";
import { IRecipe, Recipe, RecipeData } from "../models/recipe.model";
import {
	createContentsForMilkBankProducts,
	getCombinedMilkState,
	getCombinedMilkStateFromStates,
	getContentsFromRecipe,
} from "../utils/milk-label.util";
import { getEarliestDayjs } from "../utils/expiration.util";
import { PatientEhrOrderModel, PatientModel } from "../models/patient.model";
import { lastValueFrom } from "rxjs";
import { getCombinedFeedState, getTotalQuantity } from "../utils/feed.util";
import {
	getCaloriesFromFeedObjects,
	getEvenlyDividedVolume2,
	getProductVolumeByRecipe,
	getVolumeByRecipe,
} from "../utils/volume.util";
import { IPatientLabelQuantity } from "../components/label-quantity-list/label-quantity-list.component";
import { Product } from "../models/product.model";
import { FeedObject } from "../models/feed-object.model";
import { getMilkBankProductFromRecipe } from "../utils/milk-bank-product.util";
import {
	IMilkBottleExpiringSoonest,
	MilkBottleExpiringSoonest,
} from "../models/milk-bottle-expiring-soonest";
import { IAuthorizedTenant } from "../models/user-profile.model";
import { UiMessagesService } from "./ui-messages.service";
import { UnreceivedProduct } from "../models";
import { ReceiveParentsMilkBottleModel } from "../models/receive-parents-milk.model";

@Injectable({
	providedIn: "root",
})
export class InventoryService {
	currentPage: number;

	constructor(
		private http: RestApiService,
		private alerts: UiMessagesService
	) {}

	getMilkBottles(
		patient: { mrn: string },
		options: {
			includeExpended: boolean;
			includeExpired: boolean;
			includeUsed: boolean;
			bottleNumber?: number;
			excludeExpiredGreaterThanInDays?: number;
			includeAtHomeBottles?: boolean;
			includeDischarged?: boolean;
		}
	): Promise<MilkBottleModel[]> {
		const url = "/milkbottles";
		const params = {
			Mrn: patient.mrn,
			IncludeExpended: options?.includeExpended,
			IncludeUsed: options?.includeUsed,
			IncludeExpired: options?.includeExpended,
			...(options?.includeDischarged !== undefined && {
				IncludeDischarged: options.includeDischarged,
			}),
			...(options?.excludeExpiredGreaterThanInDays !== undefined && {
				ExcludeExpiredGreaterThanInDays:
					options.excludeExpiredGreaterThanInDays,
			}),
			...(options?.bottleNumber !== undefined && {
				BottleNumber: options.bottleNumber,
			}),
			...(options?.includeAtHomeBottles !== undefined && {
				IncludeAtHomeBottles: options.includeAtHomeBottles,
			}),
		};
		return this.http
			.get(url, params)
			.pipe(
				map((res: IMilkBottle[]) =>
					res.map((b) => new MilkBottleModel(b))
				)
			)
			.toPromise();
	}

	async getPatientInventory(
		patient: PatientModel,
		paginationAction: PaginationAction,
		// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
		options?: any
	) {
		if (paginationAction === PaginationAction.New) {
			this.currentPage = 1;
		} else if (paginationAction === PaginationAction.Append) {
			this.currentPage++;
		}

		try {
			await this.alerts.presentLoading("Retrieving milk bottles");

			const paginatedResponse = await this.getPaginatedMilkBottles(
				patient,
				{
					...options,
					pageNumber: this.currentPage,
				}
			);
			return paginatedResponse;
		} finally {
			await this.alerts.dismissLoading();
		}
	}

	async searchPatientInventory(
		patient: PatientModel,
		paginationAction: PaginationAction,
		// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
		options?: any
	) {
		try {
			await this.alerts.presentLoading("Retrieving milk bottles");

			const paginatedResponse = await this.getPatientInventory(
				patient,
				paginationAction,
				options
			);

			return paginatedResponse;
		} finally {
			await this.alerts.dismissLoading();
		}
	}

	getPaginatedMilkBottles(
		patient: { mrn: string },
		options: GetMilkBottleOptions
	): Promise<PaginatedMilkBottlesResponse> {
		const url = "/milkbottles/paginated";
		const params = {
			Mrn: patient.mrn,
			...options,
		};
		return this.http
			.get(url, params)
			.pipe(
				map((res) => ({
					items: res.items.map(
						(b: IMilkBottle) => new MilkBottleModel(b)
					),
					totalItemCount: res.totalItemCount,
					pageNumber: res.pageNumber,
					pageSize: res.pageSize,
					hasPrevious: res.hasPrevious,
					hasNext: res.hasNext,
				}))
			)
			.toPromise();
	}

	getMilkBottle(
		milkBottleId: MilkBottleModel["id"]
	): Promise<MilkBottleModel> {
		const url = `/milkbottles/${milkBottleId}`;
		return lastValueFrom(
			this.http
				.get(url)
				.pipe(map((res: IMilkBottle) => new MilkBottleModel(res)))
		);
	}

	/**
	 * Fetch the milk bottle by the short id (base32hex encoded 13 character string), used by the QR code.
	 *
	 * @param milkBottleUnifiedId HEX32 string, 13 characters
	 */
	getMilkBottleByUnifiedId(
		milkBottleUnifiedId: MilkBottleModel["unifiedId"]
	): Promise<MilkBottleModel> {
		const url = `/milkbottles/unified-id/${milkBottleUnifiedId}`;
		return lastValueFrom(
			this.http
				.get(url)
				.pipe(map((res: IMilkBottle) => new MilkBottleModel(res)))
		);
	}

	/**
	 * Returns up to 10 milk bottles that are expiring soonest.
	 */
	getSoonestExpiringMilkBottles(patient: {
		mrn: PatientModel["mrn"];
	}): Promise<MilkBottleExpiringSoonest[]> {
		const url = "/milkbottles/soonest";
		const params = {
			Mrn: patient.mrn,
		};
		return lastValueFrom(
			this.http
				.get(url, params)
				.pipe(
					map((res: IMilkBottleExpiringSoonest[]) =>
						res.map((b) => new MilkBottleExpiringSoonest(b))
					)
				)
		);
	}

	createNewMilkBottle(payload: {
		numberOfBottles: number;
		mothersId: string;
	}): Promise<MilkBottleModel[]> {
		const url = "/milkbottles/new-labels";

		return lastValueFrom(
			this.http
				.post(url, payload)
				.pipe(
					map((res: IMilkBottle[]) =>
						res.map((m) => new MilkBottleModel(m))
					)
				)
		);
	}

	createMilkBottle2(
		payload: CreateMilkBottle2Payload
	): Promise<MilkBottleModel> {
		const url = "/milkbottles";

		return lastValueFrom(
			this.http
				.post(url, payload)
				.pipe(map((res: IMilkBottle) => new MilkBottleModel(res)))
		);
	}

	createFreshMilkBottle(params: {
		mothersId: string;
		volume: number;
		calorie: number;
		pumpDate: string;
		expirationDate: string;
		receivedDate: string;
		contents: IContent[];
		sourceMilkbottleIds: string[];
		patientIds: PatientModel["id"][];
	}): Promise<MilkBottleModel> {
		const url = "/milkbottles";
		const payload = {
			mothersId: params.mothersId,
			milkState: MilkState.Fresh,
			location: MilkLocation.Hospital,
			volume: params.volume,
			calorie: params.calorie,
			pumpDate: params.pumpDate,
			expirationDate: params.expirationDate,
			receivedDate: params.receivedDate,
			dischargeDate: null,
			frozenDate: null,
			contents: [
				...params.contents.map((c) => ({
					milkBankProductId: c.milkBankProductId,
					productId: c.productId,
					contentType: c.contentType,
				})),
				{
					milkBankProductId: null,
					productId: null,
					contentType: ContentType.Parent,
				},
			],
			sourceMilkbottleIds: params.sourceMilkbottleIds,
			patientIds: params.patientIds,
		};

		return lastValueFrom(
			this.http
				.post(url, payload)
				.pipe(map((res: IMilkBottle) => new MilkBottleModel(res)))
		);
	}

	/**
	 * Formula must contain a milk state that is Open and contain an opened date
	 */
	createFormula(params: {
		mothersId: string;
		volume: number;
		calorie: number;
		expirationDate: string;
		openedDate: string;
		receivedDate: string;
		contents: IContent[];
		sourceMilkbottleIds: string[];
		patientIds: PatientModel["id"][];
	}): Promise<MilkBottleModel> {
		const url = "/milkbottles";
		const payload = {
			mothersId: params.mothersId,
			milkState: MilkState.Opened,
			location: MilkLocation.Hospital,
			volume: params.volume,
			calorie: params.calorie,
			pumpDate: null,
			expirationDate: params.expirationDate,
			receivedDate: params.receivedDate,
			openedDate: params.openedDate,
			dischargeDate: null,
			frozenDate: null,
			contents: params.contents,
			sourceMilkbottleIds: params.sourceMilkbottleIds,
			patientIds: params.patientIds,
		};

		return lastValueFrom(
			this.http
				.post(url, payload)
				.pipe(map((res: IMilkBottle) => new MilkBottleModel(res)))
		);
	}

	createAssignedMilkBankProduct(params: {
		mothersId: string;
		milkState: MilkState;
		volume: number;
		calorie: number;
		expirationDate: string;
		receivedDate: string;
		thawedDate: string;
		openedDate: string;
		contents: IContent[];
		sourceMilkbottleIds: string[];
		patientIds: PatientModel["id"][];
	}): Promise<MilkBottleModel> {
		const url = "/milkbottles";
		const payload = {
			mothersId: params.mothersId,
			milkState: params.milkState,
			location: MilkLocation.Hospital,
			volume: params.volume,
			calorie: params.calorie,
			pumpDate: null,
			expirationDate: params.expirationDate,
			receivedDate: params.receivedDate,
			thawedDate: params.thawedDate,
			openedDate: params.openedDate,
			dischargeDate: null,
			frozenDate: null,
			contents: [
				...params.contents.map((c) => ({
					milkBankProductId: c.milkBankProductId,
					productId: c.productId,
					contentType: c.contentType,
				})),
			],
			sourceMilkbottleIds: params.sourceMilkbottleIds,
			patientIds: params.patientIds,
		};

		return lastValueFrom(
			this.http
				.post(url, payload)
				.pipe(map((res: IMilkBottle) => new MilkBottleModel(res)))
		);
	}

	/**
	 * When creating combined milk for siblings, users have the option to select which patient the
	 * newly created milk bottle will be for.
	 */
	getPatientsForCreateCombinedMilk(params: {
		milkBottles: MilkBottleModel[];
		assignedPatients: PatientModel[];
	}): PatientModel["id"][] {
		if (params.assignedPatients.length > 0) {
			return params.assignedPatients.map((x) => x.id);
		}
		return getUniquePatientIds(params.milkBottles);
	}

	/**
	 * Creates combined milk bottles from a list of milk bottles
	 * If label quantity greater than 1, then it will create multiple milk bottles
	 * with the same contents (divide).
	 */
	async createCombinedMilk(params: {
		patient: PatientModel;
		assignedPatients: PatientModel[];
		milkBottles: MilkBottleModel[];
		recipeData: RecipeData;
		labelQuantity: number;
	}): Promise<MilkBottleModel[]> {
		const createdMilks: MilkBottleModel[] = [];
		const { assignedPatients, milkBottles } = params;

		for (let i = 0; i < params.labelQuantity; i++) {
			const created = await this.createMilkBottle2({
				mothersId: params.patient.motherId,
				milkState: getCombinedMilkState(params.milkBottles),
				location: MilkLocation.Hospital,
				volume: params.milkBottles[0].volume, // take first because they should all be the same
				calorie: params.recipeData
					? params.recipeData.calorieDensity
					: params.milkBottles[0].calorieDensity, // take first because they should all be the same
				pumpDate: getEarliestDayjs(
					params.milkBottles,
					(m) => m.pumpDate
				)?.toISOString(),
				expirationDate: null, // FIXME: this is no longer used (ML-1251)
				receivedDate: getEarliestDayjs(
					params.milkBottles,
					(m) => m.receivedDate
				)?.toISOString(),
				dischargeDate: null,
				thawedDate: getEarliestDayjs(
					params.milkBottles,
					(m) => m.thawedDate
				)?.toISOString(),
				openedDate: getEarliestDayjs(
					params.milkBottles,
					(m) => m.openedDate
				)?.toISOString(),
				frozenDate: getEarliestDayjs(
					params.milkBottles,
					(m) => m.frozenDate
				)?.toISOString(),
				// if source milk is provided, contents doesn't need to be
				contents: params.recipeData
					? getContentsFromRecipe(params.recipeData)
					: [],
				sourceMilkbottleIds: params.milkBottles.map((m) => m.id),
				patientIds: this.getPatientsForCreateCombinedMilk({
					assignedPatients,
					milkBottles,
				}),
			});
			createdMilks.push(created);
		}
		return createdMilks;
	}

	async assignMilkBankProducts(params: {
		milkBankProducts: MilkBankProductModel[];
		recipeData: RecipeData;
		labelQuantities: IPatientLabelQuantity[];
	}): Promise<FeedObject[]> {
		const { milkBankProducts, recipeData, labelQuantities } = params;
		const patient = labelQuantities.find(
			(x) => x.labelQuantity > 0
		).patient;
		return [
			await this.createAssignedMilkBankProduct({
				mothersId: patient.motherId,
				milkState: getCombinedMilkStateFromStates(
					milkBankProducts.map(
						(p) => p.productState.toString() as MilkState
					)
				),
				volume: getProductVolumeByRecipe({
					recipeData,
					labelQuantity: getTotalQuantity(labelQuantities),
				}),
				calorie: recipeData
					? recipeData.calorieDensity
					: (milkBankProducts[0].calorieDensity ?? 0), // should all be the same
				expirationDate: getEarliestDayjs(
					milkBankProducts,
					(m) => m.expirationDate
				)?.toISOString(),
				receivedDate: getEarliestDayjs(
					milkBankProducts,
					(m) => m.receivedDate
				)?.toISOString(),
				thawedDate: getEarliestDayjs(
					milkBankProducts,
					(m) => m.thawedDate
				)?.toISOString(),
				openedDate: getEarliestDayjs(
					milkBankProducts,
					(m) => m.openedDate
				)?.toISOString(),
				contents: createContentsForMilkBankProducts(
					milkBankProducts,
					recipeData
				),
				sourceMilkbottleIds: [],
				patientIds: [patient.id],
			}),
		];
	}

	/**
	 * TODO: When combining assigned feeds with milk bank products, the milk bank products
	 *  need to be assigned to the patient because we need to reconcile the various dates
	 *  (i.e. thawed date, pump date, opened date, etc.). Currently I think the best way
	 *  to achieve this is to assign the milk bank product to each patient separately in
	 *  order to create the combined milk bottle.
	 *
	 * For each patient, create as many combined milk bottles as specified in the quantity.
	 * @param params
	 */
	async createCombinedFeed(params: {
		feedObjects: FeedObject[];
		recipeData: RecipeData;
		labelQuantities: IPatientLabelQuantity[];
	}): Promise<MilkBottleModel[]> {
		const { feedObjects, recipeData, labelQuantities } = params;
		const createdFeeds: MilkBottleModel[] = [];
		const milkBottles = feedObjects.filter(
			(x) => x instanceof MilkBottleModel
		) as MilkBottleModel[];
		for (const data of labelQuantities) {
			const { patient, labelQuantity } = data;
			const milkBankProducts = feedObjects.filter(
				(f) => f instanceof MilkBankProductModel
			) as MilkBankProductModel[];

			// TODO: move this to a separate function?
			// Assign if there are milk bank products
			let assignedMilkBankProducts: MilkBottleModel[] = [];
			if (milkBankProducts.length > 0) {
				assignedMilkBankProducts = (await this.assignMilkBankProducts({
					milkBankProducts,
					recipeData,
					labelQuantities,
				})) as MilkBottleModel[];
			}

			const combinableFeeds = [
				...milkBottles,
				...assignedMilkBankProducts,
			];
			for (let i = 0; i < labelQuantity; i++) {
				const created = await this.createMilkBottle2({
					mothersId: patient.motherId,
					milkState: getCombinedFeedState(combinableFeeds),
					location: MilkLocation.Hospital,
					volume: getEvenlyDividedVolume2({
						feedObjects: combinableFeeds as FeedObject[],
						totalVolume: getVolumeByRecipe(recipeData),
						labelQuantity: getTotalQuantity(labelQuantities),
					}),
					calorie: recipeData
						? recipeData.calorieDensity
						: getCaloriesFromFeedObjects(combinableFeeds), // take first because they should all be the
					// same
					// TODO: milk bank products don't have a pump date
					pumpDate: getEarliestDayjs(
						combinableFeeds,
						(m) => m.pumpDate
					)?.toISOString(),
					expirationDate: null, // FIXME: this is no longer used (ML-1251)
					receivedDate: getEarliestDayjs(
						getMilkBankProductFromRecipe(recipeData),
						(m) => m.receivedDate
					)?.toISOString(),
					dischargeDate: null,
					thawedDate: getEarliestDayjs(
						combinableFeeds,
						(m) => m.thawedDate
					)?.toISOString(),
					openedDate: getEarliestDayjs(
						combinableFeeds,
						(m) => m.openedDate
					)?.toISOString(),
					frozenDate: getEarliestDayjs(
						combinableFeeds,
						(m) => m.frozenDate
					)?.toISOString(),
					// if source milk is provided, contents doesn't need to be
					contents: recipeData
						? [...getContentsFromRecipe(recipeData)]
						: [],
					sourceMilkbottleIds: combinableFeeds.map((m) => m.id),
					patientIds: [patient.id], // assign to specific patient
				});
				createdFeeds.push(created);
			}
		}

		return createdFeeds;
	}

	updateMilkBottle3(milkBottle: MilkBottleModel): Promise<MilkBottleModel> {
		const url = `/milkbottles/${milkBottle.id}`;
		const payload = {
			milkState: milkBottle.milkState,
			location: milkBottle.location,
			volume: milkBottle.volume || 0,
			calorie: milkBottle.calorieDensity || 0,
			pumpDate: milkBottle.pumpDate
				? milkBottle.pumpDate.toISOString()
				: null,
			expendedDate: milkBottle.expendedDate
				? milkBottle.expendedDate.toISOString()
				: null,
			expirationDate: milkBottle.expirationDate
				? milkBottle.expirationDate.toISOString()
				: null,
			receivedDate: milkBottle.receivedDate
				? milkBottle.receivedDate.toISOString()
				: null,
			dischargeDate: milkBottle.dischargeDate
				? milkBottle.dischargeDate.toISOString()
				: null,
			frozenDate: milkBottle.frozenDate
				? milkBottle.frozenDate.toISOString()
				: null,
			thawedDate: milkBottle.thawedDate
				? milkBottle.thawedDate.toISOString()
				: null,
			openedDate: milkBottle.openedDate
				? milkBottle.openedDate.toISOString()
				: null,
			contents: [
				...milkBottle.contents.map((c) => ({
					id: c.id || null,
					milkBottleId: c.milkBottleId,
					milkBankProductId: c.milkBankProductId || null,
					productId: c.productId,
					contentType: c.contentType,
				})),
			],
			sourceMilkBottleIds: milkBottle.sourceMilkBottleIds,
			patientIds: milkBottle.patients.map((p) => p.id),
		} as UpdateMilkBottlePayload;

		return lastValueFrom(
			this.http.put(url, payload).pipe(
				map((res: IMilkBottle) => {
					const milkBottleResponse = new MilkBottleModel(res);
					milkBottleResponse.isInlineThawed =
						milkBottle.isInlineThawed;
					return milkBottleResponse;
				})
			)
		);
	}

	/**
	 * set location to 'Home'
	 * set discharge date to now
	 */
	async dischargeMilkBottle(
		milkBottle: MilkBottleModel
	): Promise<MilkBottleModel> {
		milkBottle.location = MilkLocation.Home;
		milkBottle.dischargeDate = dayjs();
		return await this.updateMilkBottle3(milkBottle);
	}

	/**
	 * - set location to hospital
	 * - set received date to now
	 * @deprecated Use receiveParentsMilk
	 * to be updated in ML-2771
	 */
	receiveMilkBottle(milkBottle: MilkBottleModel): Promise<MilkBottleModel> {
		milkBottle.location = MilkLocation.Hospital;
		milkBottle.receivedDate = dayjs();
		return this.updateMilkBottle3(milkBottle);
	}

	receiveParentsMilk(
		milkBottles: MilkBottleModel[]
	): Promise<MilkBottleModel[]> {
		const url = "/milkbottles/receive";
		const payload = {
			MilkBottles: milkBottles.map(
				(m) => new ReceiveParentsMilkBottleModel(m)
			),
		};

		return lastValueFrom(
			this.http
				.put(url, payload)
				.pipe(
					map((res: IMilkBottle[]) =>
						res.map(
							(bottle: IMilkBottle) => new MilkBottleModel(bottle)
						)
					)
				)
		);
	}

	deleteMilkBottles(milkBottleIds: string[]): Promise<void> {
		const url = "/milkbottles/delete";
		const payload = {
			milkBottleIds,
		};

		return lastValueFrom(this.http.post(url, payload));
	}

	async getTenantMilkBankProducts(
		paginationAction: PaginationAction,
		// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
		options?: any
	) {
		if (paginationAction === PaginationAction.New) {
			this.currentPage = 1;
		}

		if (paginationAction === PaginationAction.Append) {
			this.currentPage++;
		}

		try {
			const paginatedResponse = await this.getPaginatedMilkBankProducts({
				...options,
				pageNumber: this.currentPage,
			});
			return paginatedResponse;
		} catch (error) {
			console.error(error);
		}
	}

	async searchTenantMilkBankProducts(
		paginationAction: PaginationAction,
		// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
		options?: any
	) {
		try {
			const paginatedResponse = await this.getTenantMilkBankProducts(
				paginationAction,
				options
			);

			return paginatedResponse;
		} catch (error) {
			console.error(error);
		}
	}

	getPaginatedMilkBankProducts(
		params: GetMilkBankProductParameters
	): Promise<PaginatedMilkBankProductResponse> {
		const url = "/milkbankproducts/paginated";
		return this.http
			.get(url, params)
			.pipe(
				map((res) => ({
					items: res.items.map(
						(b: IMilkBankProduct) => new MilkBankProductModel(b)
					),
					totalItemCount: res.totalItemCount,
					pageNumber: res.pageNumber,
					pageSize: res.pageSize,
					hasPrevious: res.hasPrevious,
					hasNext: res.hasNext,
				}))
			)
			.toPromise();
	}

	getMilkBankProducts(
		params: GetMilkBankProductParameters
	): Promise<MilkBankProductModel[]> {
		const url = "/milkbankproducts";

		return lastValueFrom(
			this.http
				.get(url, _.omitBy(params, _.isNull))
				.pipe(
					map((res: IMilkBankProduct[]) =>
						res.map((m) => new MilkBankProductModel(m))
					)
				)
		);
	}

	getMilkBankProductById(
		id: MilkBankProductModel["id"]
	): Promise<MilkBankProductModel> {
		const url = `/milkbankproducts?Ids=${id}`;

		return lastValueFrom(
			this.http
				.get(url)
				.pipe(
					map((res: IMilkBankProduct[]) =>
						res.length ? new MilkBankProductModel(res[0]) : null
					)
				)
		);
	}

	getMilkBankProductByUnifiedId(
		id: MilkBankProductModel["unifiedId"]
	): Promise<MilkBankProductModel> {
		const url = `/milkbankproducts?UnifiedIds=${id}`;

		return lastValueFrom(
			this.http
				.get(url)
				.pipe(
					map((res: IMilkBankProduct[]) =>
						res.length ? new MilkBankProductModel(res[0]) : null
					)
				)
		);
	}

	getMilkBankProductByQRCode(
		milkBankProductQRCode: MilkTrackerMilkBankProductQRCode
	): Promise<MilkBankProductModel> {
		const milkBankProductQRCodeIdType = milkBankProductQRCode.id.type;
		switch (milkBankProductQRCodeIdType) {
			case "guid":
				return this.getMilkBankProductById(
					milkBankProductQRCode.id.milkBankProductId
				);
			case "unifiedId":
				return this.getMilkBankProductByUnifiedId(
					milkBankProductQRCode.id.milkBankProductUnifiedId
				);
			default:
				assertExhaustive(milkBankProductQRCodeIdType);
		}
	}

	/**
	 * @deprecated
	 * This returns one milk bank product, but we need to return all
	 * and then check product_id, lot_number, and bottle_number,
	 * to see if the product has already been received.
	 *
	 * TODO: ML-2200
	 */
	getMilkBankProductByBarcode(
		barcode: string
	): Promise<MilkBankProductModel> {
		const url = `/milkbankproducts?Barcode=${barcode}`;

		return lastValueFrom(
			this.http
				.get(url)
				.pipe(
					map((res: IMilkBankProduct[]) =>
						res.length ? new MilkBankProductModel(res[0]) : null
					)
				)
		);
	}

	getMilkBankProductsByBarcode(
		barcode: ScannedObject["barcode"]["text"]
	): Promise<MilkBankProductModel[]> {
		const url = `/milkbankproducts?Barcode=${barcode}`;

		return lastValueFrom(
			this.http
				.get(url)
				.pipe(
					map((res: IMilkBankProduct[]) =>
						res.length
							? res.map(
									(milkBankProduct) =>
										new MilkBankProductModel(
											milkBankProduct
										)
								)
							: null
					)
				)
		);
	}

	/**
	 * @deprecated
	 * To be updated in ML-2199
	 */
	async getAlreadyReceivedMilkBankProduct(params: {
		scannedObject: ScannedObject;
		tenant: IAuthorizedTenant;
	}): Promise<MilkBankProductModel> {
		const { scannedObject, tenant } = params;

		const barcode = scannedObject.barcode.text;
		const milkBankProducts =
			await this.getMilkBankProductsByBarcode(barcode);

		return milkBankProducts?.find((milkBankProduct) => {
			const hasLotNumberMatch =
				milkBankProduct.lotNumber ===
				scannedObject.match.groups?.lotCode;
			const hasProductIdMatch =
				milkBankProduct.productId === scannedObject.product.id;
			const hasTenantIdMatch =
				milkBankProduct.receivedByTenantId.toString() ===
				tenant.tenantId.toString();
			const bottleNumber = scannedObject.match.groups?.bottleNumber
				? Number.parseInt(scannedObject.match.groups?.bottleNumber)
				: 0;
			const hasBottleNumberMatch =
				milkBankProduct.bottleNumber === bottleNumber;

			return (
				hasTenantIdMatch &&
				hasLotNumberMatch &&
				hasProductIdMatch &&
				hasBottleNumberMatch
			);
		});
	}

	createMilkBankProduct(
		milkBankProduct: MilkBankProductModel
	): Promise<MilkBankProductModel> {
		if (!milkBankProduct.manufacturerExpirationDate) {
			return Promise.reject(
				"InventoryService.createMilkBankProduct: manufacturerExpirationDate is null"
			);
		}

		const url = "/milkbankproducts";
		const payload = milkBankProduct.toPayload();

		return lastValueFrom(
			this.http
				.post(url, payload)
				.pipe(
					map((res: IMilkBankProduct[]) =>
						res.length === 1
							? new MilkBankProductModel(res[0])
							: null
					)
				)
		);
	}

	/**
	 * Receives products by creating them as milk bank products.
	 *
	 * Ensure expendedDate is also set
	 *
	 * @param product is a Product model that has been extended to include other
	 * properties such as lotNumber, expirationDate, etc. For example,
	 *
	 * 		const unreceivedProduct = new Product({
	 * 			...product,
	 * 			lotNumber: lotCode,
	 * 			manufacturerExpirationDate,
	 * 			bottleNumber,
	 * 			barcodeText: scannedObject.barcode.text,
	 * 			productState,
	 * 			thawedDate,
	 * 			openedDate,
	 * 		});
	 *
	 */
	receiveProduct(product: Product): Promise<MilkBankProductModel> {
		const milkBankProduct = new MilkBankProductModel({
			barcode: product.barcodeText,
			defective: false,
			bottleNumber: Number.parseInt(product.bottleNumber),
			defectiveReason: null,
			productId: product.id,
			manufacturerExpirationDate:
				product.manufacturerExpirationDate.toISOString(),
			lotNumber: product.lotNumber,
			productState: product.productState,
			productManufacturer: product.manufacturerName,
			manufacturerCreatedDate: null,
			expendedDate: product.expendedDate?.toISOString(),
			openedDate: product.openedDate?.toISOString(),
		} as IMilkBankProduct);
		return this.createMilkBankProduct(milkBankProduct);
	}

	createMilkBankProducts(
		products: MilkBankProductModel[]
	): Promise<MilkBankProductModel[]> {
		const url = "/milkbankproducts/receivelot";
		const payload = {
			products: products.map((p) => ({
				barcode: p.barcode,
				bottleNumber: Number(p.bottleNumber),
				defective: p.defective,
				defectiveReason: p.defectiveReason,
				expendedDate: p.expendedDate
					? p.expendedDate.toISOString()
					: null,
				expirationDate: p.manufacturerExpirationDate
					? p.manufacturerExpirationDate.toISOString()
					: null,
				lotNumber: p.lotNumber,
				manufacturerCreatedDate: p.manufacturerCreatedDate
					? p.manufacturerCreatedDate.toISOString()
					: null,
				manufacturerExpirationDate: p.manufacturerExpirationDate
					? p.manufacturerExpirationDate.toISOString()
					: null,
				openedDate: p.openedDate ? p.openedDate.toISOString() : null,
				productId: p.productId,
				productState: p.productState,
				thawedDate: p.thawedDate ? p.thawedDate.toISOString() : null,
			})),
		};

		return lastValueFrom(
			this.http.post(url, payload).pipe(
				// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
				catchError((e: any) => {
					throw e;
				}),
				map((res: IMilkBankProduct[]) =>
					res.map((p) => new MilkBankProductModel(p))
				)
			)
		);
	}

	isMilkBankProductUnreceived(product: UnreceivedProduct): Promise<boolean> {
		const url = "/milkbankproducts/isunreceived";
		const p = product;
		const payload = {
			barcode: p.barcode,
			bottleNumber: p.bottleNumber,
			defective: p.defective,
			defectiveReason: p.defectiveReason,
			expirationDate: p.manufacturerExpirationDate
				? p.manufacturerExpirationDate.toISOString()
				: null,
			lotNumber: p.lotNumber,
			manufacturerCreatedDate: p.manufacturerCreatedDate
				? p.manufacturerCreatedDate.toISOString()
				: null,
			manufacturerExpirationDate: p.manufacturerExpirationDate
				? p.manufacturerExpirationDate.toISOString()
				: null,
			productId: p.productId,
			productState: p.productState,
		};

		return lastValueFrom(
			this.http.post(url, payload).pipe(
				// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
				catchError((e: any) => {
					throw e;
				}),
				map((res: boolean) => res)
			)
		);
	}

	thawLot(
		milkBankProductIds: ThawLotRequest
	): Promise<MilkBankProductModel[]> {
		const url = "/milkbankproducts/thawlot";

		return lastValueFrom(
			this.http
				.put(url, milkBankProductIds)
				.pipe(
					map((res: IMilkBankProduct[]) =>
						res.map((product) => new MilkBankProductModel(product))
					)
				)
		);
	}

	updateMilkBankProduct(
		milkBankProduct: MilkBankProductModel
	): Promise<MilkBankProductModel> {
		const url = `/milkbankproducts/${milkBankProduct.id}`;
		const payload = milkBankProduct.toPayload();

		return lastValueFrom(
			this.http
				.put(url, payload)
				.pipe(
					map(
						(res: IMilkBankProduct) => new MilkBankProductModel(res)
					)
				)
		);
	}

	getRecipes(params?: {
		BaseType?: string;
		BaseProductId?: Product["id"];
		FinalCalorieDensity?: number;
	}): Promise<Recipe[]> {
		const url = "/recipe";
		let queryParams = new HttpParams();

		if (params?.BaseType) {
			queryParams = queryParams.append("BaseType", params.BaseType);
		}

		if (params?.BaseProductId) {
			queryParams = queryParams.append(
				"BaseProductId",
				params.BaseProductId
			);
		}

		if (params?.FinalCalorieDensity) {
			queryParams = queryParams.append(
				// biome-ignore lint/nursery/noSecrets: Not a secret
				"FinalCalorieDensity",
				params.FinalCalorieDensity
			);
		}

		return lastValueFrom(
			this.http
				.get(url, queryParams)
				.pipe(map((res: IRecipe[]) => res.map((r) => new Recipe(r))))
		);
	}

	/**
	 * A bottle that has been administered has been expended.
	 */
	administerMilkBottle(payload: {
		milkBottleId: MilkBottleModel["id"];
		patientId: PatientModel["id"];
		orderId?: PatientEhrOrderModel["id"];
		reason: MilkBottleUseType;
		isOverride: boolean;
		overrideVerifiedBy?: string;
		overrideReason?: string;
	}): Promise<MilkBottleModel> {
		const { milkBottleId, ...rest } = payload;
		const url = `/milkbottles/${milkBottleId}/administer`;

		return lastValueFrom(
			this.http
				.post(url, rest)
				.pipe(map((res: IMilkBottle) => new MilkBottleModel(res)))
		);
	}
}

export interface CreateMilkBottle2Payload {
	mothersId: string;
	milkState: MilkState;
	location: MilkLocation;
	volume: number;
	calorie: number;
	pumpDate: string;
	expirationDate: string;
	receivedDate: string;
	dischargeDate: string;
	frozenDate: string;
	// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
	contents: IContent[] | any;
	sourceMilkbottleIds: string[];
	patientIds: PatientModel["id"][];
	openedDate: string;
	thawedDate: string;
}

export interface UpdateMilkBottlePayload {
	milkState: MilkState;
	location: MilkLocation;
	volume: number;
	calorie: number;
	pumpDate: string;
	expirationDate: string;
	receivedDate: string;
	dischargeDate: string;
	frozenDate: string;
	thawedDate: string;
	// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2882
	contents: any[];
	sourceMilkBottleIds: MilkBottleModel["id"][];
	patientIds: PatientModel["id"][];
}

export interface GetMilkBankProductParameters {
	active?: boolean;
	ids?: MilkBankProductModel["id"][];
	unifiedIds?: MilkBankProductModel["unifiedId"][];
	barcode?: MilkBankProductModel["barcode"];
	isAssigned?: boolean;
	queryText?: string;
	excludeExpiredGreaterThanDays?: number;
	includeFromAllTenants?: boolean;
	includeExpended?: boolean;
	inventoryCategory?: InventoryCategory;
	pageNumber?: number;
	pageSize?: number;
}

export interface GetMilkBottleOptions {
	includeExpended?: boolean;
	includeUsed?: boolean;
	includeExpired?: boolean;
	includeDischarged?: boolean;
	excludeExpiredGreaterThanInDays?: number;
	bottleNumber?: string;
	includeAtHomeBottles?: boolean;
	pageNumber?: number;
	pageSize?: number;
	searchInput?: string;
	inventoryCategory?: InventoryCategory;
}

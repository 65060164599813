import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationExtras } from '@angular/router';

import { environment } from 'src/environments/environment';

import {
	AppConfigs,
	ConfigModel,
	IAppConfigs,
} from 'src/app/models/config.model';
import { IPrinter, PrinterModel } from 'src/app/models/printer.model';

import { AuthToken } from 'src/app/services/auth.service';
import {
	IAuthorizedTenant,
	IPermissions,
	UserProfileModel,
} from '../models/user-profile.model';
import {
	ExpirationPolicy,
	TenantConfigs,
} from '../models/tenant-configs.model';
import { IFeatureFlag } from '../features.enums';
import { VIRTUAL_PRINTER_ID } from '../app.constants';

@Injectable({
	providedIn: 'root',
})
// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class StorageService {
	private static _isWeb: boolean;
	private static _isMenuOpen = false;
	private static _authToken: AuthToken;
	private static _nurseId: string;
	private static _subscriptions: Subscription[] = [];

	private static _user: UserProfileModel;
	private static _activeTenant: IAuthorizedTenant;
	private static _configs: ConfigModel;

	private static _appConfigs: AppConfigs;

	private static _isClerk = false;

	static get isWeb(): boolean {
		return StorageService._isWeb;
	}

	static set isWeb(isWeb: boolean) {
		StorageService._isWeb = isWeb;
	}

	static get isMenuOpen(): boolean {
		return StorageService._isMenuOpen;
	}

	static set isMenuOpen(isMenuOpen: boolean) {
		StorageService._isMenuOpen = isMenuOpen;
	}

	static get user(): UserProfileModel {
		if (environment.production) {
			return StorageService._user;
		}
		return localStorage.getItem('user')
			? new UserProfileModel(JSON.parse(localStorage.getItem('user')))
			: null;
	}

	static set user(user: UserProfileModel) {
		if (environment.production) {
			StorageService._user = user;
			return;
		}

		if (!user) {
			localStorage.removeItem('user');
			return;
		}

		localStorage.setItem('user', JSON.stringify(user));
	}

	static set isClerk(isClerk: boolean) {
		StorageService._isClerk = isClerk;
	}

	static get isClerk(): boolean {
		return StorageService._isClerk;
	}

	//*--- Tenant specific getters/setters ---*//

	/*
		FIXME: These values are already stored in the user object.
		 Make sure it's not out of sync. I ran into transient issues where
		 the permission overrides weren't working for some reasons related to
		 caching.
	 */
	static get activeTenant(): IAuthorizedTenant {
		if (environment.production) {
			return StorageService._activeTenant;
		}

		return JSON.parse(localStorage.getItem('activeTenant'));
	}

	/**
	 * Sets the active tenant.
	 * If there are local overrides for permissions, apply them.
	 */
	static set activeTenant(tenant: IAuthorizedTenant) {
		if (environment.production) {
			StorageService._activeTenant = tenant;
			return;
		}

		if (!tenant) {
			localStorage.removeItem('activeTenant');
			return;
		}

		if (environment.settings?.overridePermissions) {
			// console.warn("Overriding tenant permissions...");
			tenant.permissions = environment.settings.overridePermissions;
		}

		localStorage.setItem('activeTenant', JSON.stringify(tenant));
	}

	static set preferredTenant(tenant: IAuthorizedTenant) {
		if (!tenant) {
			localStorage.removeItem('preferredTenant');
		}

		localStorage.setItem('preferredTenant', JSON.stringify(tenant));
	}

	static get preferredTenant(): IAuthorizedTenant {
		return JSON.parse(localStorage.getItem('preferredTenant'));
	}

	static get permissions(): IPermissions {
		return StorageService.activeTenant?.permissions || null;
	}

	// Ideally, this is nested under tenant, but we had problems mocking the data easily for tests.
	static get features(): IFeatureFlag {
		return StorageService.configs.features || null;
	}

	static get configs(): ConfigModel {
		if (environment.production) {
			return StorageService._configs;
		}

		return localStorage.getItem('configs')
			? new ConfigModel(JSON.parse(localStorage.getItem('configs')))
			: null;
	}

	static set configs(configs: ConfigModel) {
		if (environment.production) {
			StorageService._configs = configs;
			return;
		}

		// clear configs if null
		if (!configs) {
			localStorage.removeItem('configs');
		} else {
			if (configs && environment.settings?.overrideFeatureFlags) {
				// console.warn("Overriding feature flags...");
				configs.features = environment.settings.overrideFeatureFlags;
			}

			localStorage.setItem('configs', configs?.toString());
		}
	}

	static get defaultEbm() {
		return StorageService.configs.defaultEbm;
	}

	// Configs - printer

	/**
	 * If the global_VirtualPrinting permission is enabled, add a virtual printer to the list of printers.
	 * Permissions are set in IAuthorizedTenant, not ITenant so it's better to pass it as a parameter.
	 */
	static get printers(): PrinterModel[] {
		const isVirtualPrintingEnabled =
			StorageService.permissions?.global_VirtualPrinting;

		const virtualPrinter = [
			new PrinterModel({
				id: VIRTUAL_PRINTER_ID,
				name: 'Virtual Printer',
			} as IPrinter),
		];

		const printers = [
			...(isVirtualPrintingEnabled ? virtualPrinter : []),
			...StorageService.configs.printers.map((p) => new PrinterModel(p)),
		];

		return printers;
	}

	// App Info getters/setters

	static get appConfigs(): AppConfigs {
		if (environment.production) {
			return StorageService._appConfigs;
		}
		return new AppConfigs(
			JSON.parse(localStorage.getItem('appConfigs')) as IAppConfigs,
		);
	}

	static set appConfigs(configs: AppConfigs) {
		// console.log("Setting app info");
		if (environment.production) {
			StorageService._appConfigs = configs;
		}

		if (!configs) {
			localStorage.removeItem('appConfigs');
			return;
		}

		localStorage.setItem('appConfigs', configs.toString());
	}

	static get appVersion(): string {
		return StorageService.appConfigs?.appVersion || null;
	}

	static get commitHash(): string {
		if (!StorageService.appConfigs?.appBuildNumber) {
			return null;
		}

		return StorageService.appConfigs?.appBuildNumber.slice(0, 7);
	}

	// Tenant configuration getters/setters

	static get tenantConfigs(): TenantConfigs {
		return StorageService.configs?.tenant;
	}

	static get expirationPolicy(): ExpirationPolicy {
		return StorageService.configs?.tenant.expirationPolicy || null;
	}

	/**
	 * This is used to determine if order validation is enforced. If the app
	 * configs aren't set to bypass validation (this is generally used for
	 * testing purposes), then check the tenant configs whether it must
	 * validate orders.
	 */
	static get isOrderValidationEnforced(): boolean {
		if (StorageService.appConfigs?.bypassOrderMatching) {
			console.warn('[environment] Bypassing order matching');
			return false;
		}

		// Override in dev environment if enabled
		if (!environment.production && environment.settings?.bypassOrderMatching) {
			console.warn('[environment] Bypassing order matching');
			return false;
		}

		return StorageService.tenantConfigs?.validateOrders;
	}

	/**
	 * If no printer is selected, return null.
	 */
	static get lastUsedCachedPrinter(): PrinterModel {
		const printer: string = localStorage.getItem('lastUsedPrinter');

		if (!printer) {
			return null;
		}

		return new PrinterModel(JSON.parse(printer));
	}

	static set lastUsedCachedPrinter(printer: PrinterModel) {
		if (!printer) {
			localStorage.removeItem('lastUsedPrinter');
			return;
		}

		localStorage.setItem('lastUsedPrinter', JSON.stringify(printer));
	}

	static set printPreview(navigationExtras: NavigationExtras) {
		if (!navigationExtras) {
			localStorage.removeItem('printPreview');
			return;
		}

		localStorage.setItem('printPreview', JSON.stringify(navigationExtras));
	}

	static get printPreview(): NavigationExtras {
		const printerString = localStorage.getItem('printPreview');
		if (!printerString) {
			return null;
		}

		return JSON.parse(printerString);
	}

	/*
		Subscription Management
	 */
	static addSubscription(subscription: Subscription): Subscription {
		StorageService._subscriptions.push(subscription);
		// console.log(`BaseService.addSubscription: total=${this._subscriptions.length}`);
		return subscription;
	}

	static removeSubscription(subscription: Subscription): void {
		subscription.unsubscribe();
		const index = StorageService._subscriptions.indexOf(subscription);
		if (index >= 0) {
			StorageService._subscriptions.splice(index, 1);
			console.log(
				`BaseService.removeSubscription: total=${StorageService._subscriptions.length}`,
			);
		}
	}

	static unsubscribeAll(): void {
		console.log(
			`BaseService.unsubscribeAll: total=${StorageService._subscriptions.length}`,
		);
		if (StorageService._subscriptions.length) {
			StorageService._subscriptions.forEach((subscription: Subscription) =>
				subscription.unsubscribe(),
			);
			StorageService._subscriptions = [];
		}
	}

	static getSubscriptionCount(): number {
		return StorageService._subscriptions
			? StorageService._subscriptions.length
			: 0;
	}

	static get authToken(): string {
		if (environment.production) {
			return StorageService._authToken?.token;
		}

		return sessionStorage.getItem('access_token');
	}

	/**
	 * Store in-memory in singleton rather than in localStorage for marginal security improvement
	 * Setting the auth tokens is related to login, so log the user in Datadog.
	 *
	 * Resources:
	 * https://medium.com/@benjamin.botto/secure-access-token-storage-with-single-page-applications-part-1-9536b0021321
	 */
	static set authToken(authToken: AuthToken) {
		// console.log("Set auth token...");
		if (environment.production) {
			StorageService._authToken = authToken;
			return;
		}

		// clear token if null
		if (!authToken) {
			sessionStorage.removeItem('access_token');
		} else {
			sessionStorage.setItem('access_token', authToken.token);
		}
	}

	/**
	 * This is used to save the last selected tenant for a user. If the user
	 * has a last selected tenant, set the tenant to that. If the user does not
	 * add it to the list.
	 *
	 * @param nurseId
	 * @param tenantId
	 */
	static updateActiveTenant(nurseId: string, tenantId: string) {
		const savedTenants = JSON.parse(localStorage.getItem('savedTenants'));
		if (savedTenants) {
			const tenant = savedTenants.find(
				(t) => t.nurseId === StorageService.user.nurseId,
			);
			if (tenant) {
				tenant.tenantId = tenantId;
			} else {
				savedTenants.push({
					nurseId,
					tenantId,
				});
			}
		}
		localStorage.setItem('savedTenants', JSON.stringify(savedTenants));
		console.log('Update active tenant');
	}

	static removeSavedTenants() {
		localStorage.removeItem('savedTenants');
	}

	/**
	 * On click of the commit hash, copy it to the clipboard
	 */
	static copyCommitHash(): Promise<boolean> {
		const dummy = document.createElement('textarea');
		// dummy.style.display = 'none'
		document.body.appendChild(dummy);
		dummy.value = StorageService.commitHash;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);
		return Promise.resolve(true);
	}

	/**
	 * Set the tenant with preference to this order:
	 *   - preferred tenant (only saved when a user has explicitly set it)
	 *   - saved tenant
	 *   - first authorized tenant
	 */
	static async setTenant() {
		let tenant: IAuthorizedTenant = undefined;

		tenant = StorageService.getPreferredTenant();

		if (tenant) {
			StorageService.preferredTenant = tenant;
		}

		if (!tenant) {
			tenant = StorageService.getStoredTenant(StorageService.user.nurseId);
		}

		if (!tenant) {
			tenant = StorageService.user.authorizedTenants[0];
		}

		StorageService.saveTenant(tenant);
	}

	/**
	 * Return the preferred tenant from the user's profile.
	 */
	private static getPreferredTenant(): IAuthorizedTenant {
		if (!StorageService.user.preferredTenantId) {
			return undefined;
		}

		return StorageService.user.authorizedTenants.find(
			(x) => x.tenantId === StorageService.user.preferredTenantId,
		);
	}

	/**
	 * Find the user's tenant from local storage.
	 */
	private static getStoredTenant(
		nurseId: UserProfileModel['nurseId'],
	): IAuthorizedTenant {
		const savedTenants: ISavedTenant[] = JSON.parse(
			localStorage.getItem('savedTenants'),
		);

		if (!savedTenants) {
			return undefined;
		}

		const savedTenant = savedTenants.find(
			(savedTenant) => savedTenant.nurseId === nurseId,
		);

		if (!savedTenant) {
			return undefined;
		}

		return StorageService.user.authorizedTenants.find(
			(x) => x.tenantId === savedTenant.tenantId,
		);
	}

	/**
	 * Sets the active tenant and stores tenant to saved tenants.
	 */
	static saveTenant(tenant: IAuthorizedTenant) {
		StorageService.activeTenant = tenant;

		const savedTenants: ISavedTenant[] =
			JSON.parse(localStorage.getItem('savedTenants')) || [];

		const existing = savedTenants.find(
			(x) => x.nurseId === StorageService.user.nurseId,
		);

		if (!existing) {
			savedTenants.push({
				nurseId: StorageService.user.nurseId,
				tenantId: tenant.tenantId,
			});

			localStorage.setItem('savedTenants', JSON.stringify(savedTenants));
			console.log(`Adding tenant: ${tenant.tenantId}`);
			return;
		}

		const updateTenants = savedTenants.map((t) => {
			if (t.nurseId === StorageService.user.nurseId) {
				return {
					nurseId: t.nurseId,
					tenantId: tenant.tenantId,
				};
			}
			return t;
		});

		localStorage.setItem('savedTenants', JSON.stringify(updateTenants));
		console.log(`Saving tenant: ${tenant.tenantId}`);
	}
}

export interface ISavedTenant {
	nurseId: string;
	tenantId: string;
}

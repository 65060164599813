import {
	BaseType,
	ContentProductType,
	ContentType,
	MilkType,
	ProductType,
	RecipeMode,
	RecipePageType,
} from "src/app/app.enums";
import { ScannedObject } from "src/app/services/scanning.service";
import {
	Recipe,
	RecipeBase,
	ScannedRecipeData,
} from "src/app/models/recipe.model";
import { Product } from "../models/product.model";
import { MilkBottleModel } from "../models/milk.model";
import { StorageService } from "../services/storage.service";
import { Additive, IAdditive } from "../models/additive.model";
import { AdditiveCandidate2 } from "../modals/recipe-calculator/recipe-calculator.modal";
import { FeedObject } from "../models/feed-object.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";

/**
 * Don't show duplicate numbers, and order from least to greatest
 *
 * In Manual Prep, it should just allow the user to pick any calorie density that is within the min/max.
 */
export const getCalorieOptions2 = (
	mode: RecipeMode,
	calories: number[]
): number[] => {
	if (mode === RecipeMode.MP) {
		const min = Math.min(...calories);
		const max = Math.max(...calories);
		const range = max - min + 1; // inclusive
		return Array.from({ length: range }, (e, i) => i + min).sort();
	}

	if (mode === RecipeMode.VOH || mode === RecipeMode.DV) {
		return calories
			.filter((num, index) => calories.indexOf(num) === index)
			.sort();
	}
};

export const getCalorieRangeFromRecipe = (
	recipes: Recipe[],
	recipeBase: RecipeBase
): number[] =>
	recipes
		.filter((recipe) => recipe.baseProductId === recipeBase.baseProductId)
		.map((recipe) => recipe.calorieDensity)
		.filter((id, index, arr) => arr.indexOf(id) === index) // dedupe
		.sort();

/**
 * This is used in additive recipe calculator. Filter based on calorie density and base type must be EBM.
 */
export const getAdditivesByCalorieDensity = (
	recipes: Recipe[],
	calorieDensity: number
): IAdditive[] =>
	recipes
		.filter((r) => r.calorieDensity === calorieDensity)
		.map((r) => r.additives)
		.reduce((prev, curr) => prev.concat(curr), [])
		.sort();

export const getFormulasByBase = (
	recipes: Recipe[],
	baseName: string,
	calorieDensity: number
): IAdditive[] => {
	if (!recipes.length) {
		return;
	}

	return recipes
		.filter(
			(r) =>
				r.baseName === baseName && r.calorieDensity === calorieDensity
		)
		.map((r) => r.additives)
		.reduce((prev, curr) => prev.concat(curr), [])
		.filter(
			(a) =>
				a.productType === ProductType.Formula ||
				a.productType === ProductType.RTF
		)
		.sort();
};

export const getFormulasByCalories = (
	recipes: Recipe[],
	calorieDensity: number
): IAdditive[] => {
	if (!recipes.length) {
		return;
	}

	return recipes
		.filter((r) => r.calorieDensity === calorieDensity)
		.map((r) => r.additives)
		.reduce((prev, curr) => prev.concat(curr), []) // flatten
		.filter((a) => a.productType === ProductType.Formula);
};

/**
 * Returns the needed base volume
 * Round up to the nearest whole number
 */
export const getNeededBase = (
	desiredVolume: number,
	additive: IAdditive
): number => Math.ceil(desiredVolume * (1 - additive.productAmountPerMl));

/**
 * Returns the needed additive amount
 * Rounds 4 decimal places
 */
export const getNeededAdditive = (
	volume: number,
	additive: IAdditive
): number => {
	const additiveDisplacement = volume * additive.productAmountPerMl;
	const displacementFactor = additive.productDisplacementPerUnit || 1;
	return (
		Math.round((additiveDisplacement / displacementFactor) * 10000) / 10000
	);
};

export const getScanDataAdditives = (scanData: ScannedRecipeData) =>
	scanData.selectedRecipes
		.map((s) => s.additives)
		.reduce((prev, curr) => prev.concat(curr), [])
		.map((s) => s.additive);

/**
 * Returns the closest increment rounded up.
 *
 * e.g.
 * 17 mL at increment of 5 = 20
 * 71 mL at increment of 10 = 80
 */
export const getAdditiveAmountByIncrementRoundedDown = (
	additiveAmount: number,
	increment: number
): number => {
	const quotient = Math.floor(additiveAmount / increment);
	// const remainder = additiveAmount % increment;
	console.table({ additiveAmount, increment, quotient });
	return quotient * increment;
};

// Display functions

export const displayAdditives = (data: {
	scannedObjects?: ScannedObject[];
	additives?: IAdditive[];
	recipe?: Recipe;
}) => {
	if (data.scannedObjects) {
		const productNames = data.scannedObjects.map((o) => o.product.name);
		return productNames.join(", ");
	}

	if (data.additives) {
		const productNames = data.additives.map((a) => a.productName);
		return productNames.join(", ");
	}

	if (data.recipe) {
		const productNames = data.recipe.additives.map((a) => a.productName);
		return productNames.join(", ");
	}

	return null;
};

/**
 * If combined milk contains EBM, the mark as EBM. If not, the it's DM
 *
 * @param milks
 */
export const getPageType = (milks: MilkBottleModel[]): RecipePageType => {
	if (!milks || !milks.length) {
		return RecipePageType.EBM;
	}

	const expressedBreastMilks = milks.filter(
		(m) => m.milkType.indexOf(MilkType.EBM) > -1
	);
	const milkBankProducts = milks.filter(
		(m) =>
			m.milkType.indexOf(MilkType.DM) > -1 &&
			m.milkType.indexOf(MilkType.EBM) === -1
	);

	// They're all DM
	if (milkBankProducts.length === milks.length) {
		return RecipePageType.DM;
	}

	// There's at least one EBM, so prioritize that
	if (expressedBreastMilks.length > 0) {
		return RecipePageType.EBM;
	}
};

/**
 * Recipe page type is based on the milk type of the feed.
 * If combined milk contains EBM, then mark as EBM. If not, then it's DM
 *
 * There is some underlying assumption that the feeds are all the same type.
 */
export const getPageType2 = (feeds: FeedObject[]): RecipePageType => {
	if (!feeds || !feeds.length) {
		console.warn("getPageType: no feeds passed");
		return RecipePageType.EBM;
	}

	const expressedBreastMilks = feeds.filter((f) => {
		if (f instanceof MilkBottleModel) {
			return f.milkType.indexOf(MilkType.EBM) > -1;
		}
	});
	const milkBankProducts = feeds.filter((f) => {
		if (f instanceof MilkBottleModel) {
			return (
				f.milkType.indexOf(MilkType.DM) > -1 &&
				f.milkType.indexOf(MilkType.EBM) === -1
			);
		}
	});

	// They're all DM
	if (milkBankProducts.length === feeds.length) {
		return RecipePageType.DM;
	}

	// There's at least one EBM, so prioritize that
	if (expressedBreastMilks.length > 0) {
		return RecipePageType.EBM;
	}
};

/**
 * Returns a RecipeBase object based on the product id passed in.
 */
export const getRecipeBaseByProduct = (product: Product): RecipeBase => ({
	baseProductId: product.id,
	baseName: product.name,
	baseType: BaseType.EBM,
});

export const getDefaultEBM = (): RecipeBase => {
	const defaultEBM = StorageService.defaultEbm;

	if (!defaultEBM) {
		return null;
	}

	return {
		baseProductId: defaultEBM.id,
		baseName: defaultEBM.name,
		baseType: BaseType.EBM,
	};
};

/**
 * Returns the base product of scanned milk in the form of a RecipeBase object.
 *
 * If there are multiple bases (combined milk), prioritize EBM if they contain any.
 * If there are multiple bases and they are all milk bank products (DM), then
 * pick any of them.
 */
export const getRecipeBase = (milkBottles: MilkBottleModel[]): RecipeBase => {
	if (!milkBottles || !milkBottles.length) {
		return getDefaultEBM();
	}

	const expressedBreastMilks = milkBottles.filter(
		(m) => m.milkType.indexOf(MilkType.EBM) > -1
	);
	const milkBankProducts = milkBottles.filter(
		(m) =>
			m.milkType.indexOf(MilkType.DM) > -1 &&
			m.milkType.indexOf(MilkType.EBM) === -1
	);

	let product: Product;
	let baseType: BaseType;

	// They're all DM
	if (milkBankProducts.length === milkBottles.length) {
		const products: Product[] = StorageService.configs.getAllProducts();
		product = products.find((p) => {
			const milkBankProductContent = milkBankProducts[0].contents.find(
				(c) => c.contentType === ContentType.Donor
			);
			if (milkBankProductContent) {
				const productId = milkBankProductContent.contentProductId;
				return p.id === productId;
			}
		});
		baseType = BaseType.DM;
	}

	// There's at least one EBM, so prioritize that
	else if (expressedBreastMilks.length > 0) {
		const productId = expressedBreastMilks[0].contents.find(
			(c) => c.contentProductType === ContentProductType.EBM
		).productId;
		product = StorageService.configs.getProduct(productId);
		baseType = BaseType.EBM;
	}

	if (!product) {
		return getDefaultEBM();
	}

	return {
		baseProductId: product.id,
		baseName: product.name,
		baseType,
	};
};

/**
 * If there is an EBM, then the base is EBM.
 * If there is no EBM (all feed objects are milk bank products), then the base
 * is a product. If there are multiple products, pick one, it doesn't matter.
 * The assumption is that they will all have the same calorie density.
 *
 * @param feedObjects can be an assigned feed (milk bottle), a milk bank
 * product, or a product.
 */
export const getRecipeBase3 = (
	feedObjects: FeedObject[]
): RecipeBase | null => {
	if (!feedObjects || feedObjects.length === 0) {
		console.warn("getRecipeBase: no milk passed");
		return getDefaultEBMRecipeBase(StorageService.configs.getAllProducts());
	}

	// if all feed objects are milk bank products, then return the first one
	if (isAllMilkBankProducts(feedObjects)) {
		const milkBankProducts = getMilkBankProducts(feedObjects);
		// const product = findDonorMilkProduct(milkBankProducts[0]);
		const product = StorageService.configs.getProduct(
			milkBankProducts[0].productId
		);
		console.log(`found dm base product id: ${product.id}`);
		return {
			baseProductId: product.id,
			baseName: product.name,
			baseType: BaseType.DM,
		};
	}

	// if feed object list has at least one EBM, then return the first one
	if (hasEBM(feedObjects)) {
		const expressedBreastMilks = getExpressedBreastMilks(feedObjects);
		const product = findEBMProduct(expressedBreastMilks[0]);
		console.log(`found ebm base product id: ${product.id}`);
		return {
			baseProductId: product.id,
			baseName: product.name,
			baseType: BaseType.EBM,
		};
	}

	// if feed object contains only water (and should only be one), then return the first one
	if (isAllWater(feedObjects)) {
		const product = feedObjects[0] as Product;
		console.log(`found product base product id: ${product.id}`);
		return {
			baseProductId: product.id,
			baseName: product.name,
			baseType: BaseType.Water,
		};
	}

	// if feed object contains only products (unreceived), then return the first one
	if (isAllRTF(feedObjects)) {
		const product = feedObjects[0] as Product;
		console.log(`found product base product id: ${product.id}`);
		return {
			baseProductId: product.id,
			baseName: product.name,
			baseType: BaseType.RTF,
		};
	}

	console.warn("getRecipeBase: no base found");
	return;
};

/**
 * Check whether or not all the feed objects are donor milk.
 */
export const isAllMilkBankProducts = (feeds: FeedObject[]): boolean =>
	feeds.every((f) => f instanceof MilkBankProductModel);

/**
 * Check whether or not all the feed objects are [unreceived] products. These
 * will technically be RTF products.
 */
export const isAllRTF = (feeds: FeedObject[]): boolean =>
	feeds.every((f) => f instanceof Product && f.type === ProductType.RTF);

/**
 * Check if feed object list has at least one EBM.
 */
export const hasEBM = (feeds: FeedObject[]): boolean =>
	feeds.some(
		(f) => f instanceof MilkBottleModel && f.milkType.includes(MilkType.EBM)
	);

/**
 * Check if feed object list contains water.
 *
 * @param feedObjects
 */
export const isAllWater = (feedObjects: FeedObject[]): boolean =>
	feedObjects.every(
		(f) => f instanceof Product && f.type === ProductType.Water
	);

/**
 * Returns the first EBM product from the list of products passed in.
 * TODO: Should this be nullable?
 */
const getDefaultEBMRecipeBase = (products: Product[]): RecipeBase | null => {
	const defaultEBM = products.find((p) => p.type === ProductType.EBM);
	return defaultEBM
		? {
				baseProductId: defaultEBM.id,
				baseName: defaultEBM.name,
				baseType: BaseType.EBM,
			}
		: null;
};

const getExpressedBreastMilks = (feeds: FeedObject[]): FeedObject[] =>
	feeds.filter(
		(f) => f instanceof MilkBottleModel && f.milkType.includes(MilkType.EBM)
	);

const getMilkBankProducts = (feeds: FeedObject[]): FeedObject[] =>
	feeds.filter((f) => f instanceof MilkBankProductModel);

const findEBMProduct = (milkBottle: FeedObject): Product | undefined => {
	const content = milkBottle.contents.find(
		(c) => c.contentProductType === ContentProductType.EBM
	);

	if (content) {
		const productId = content.contentProductId;
		return StorageService.configs.getProduct(productId);
	}

	console.log("findEBMProduct: no product found");
	return undefined;
};

// TODO: This should be temporary because there is a bug with combined milk not being marked as isCombined
export const isCombined = (milk: MilkBottleModel): boolean =>
	milk.milkType === MilkType.EBM_DM ||
	milk.milkType === MilkType.DM_DM ||
	milk.milkType === MilkType.EBM_EMB ||
	milk.milkType === MilkType.EBM_DM_Formula ||
	milk.milkType === MilkType.DM_Formula ||
	milk.milkType === MilkType.EBM_Formula;

/**
 * A products has been received if the regex contains a lot code.
 */
export const isReceived = (scannedObject: ScannedObject): boolean =>
	!!scannedObject.match.groups?.lotCode;

/**
 * baseAmount * productAmountPerMl
 * Make sure to trim beyond the 4th decimal place
 */
export const getAdditiveAmount = (
	baseAmount: number,
	additive: Additive,
	increment?: number
): number => {
	// return baseAmount * additive.productAmountPerMl;
	if (increment) {
		return roundToNearestDecimal(
			baseAmount * additive.productAmountPerMl,
			increment
		);
	}
	return roundToNearest4thDecimal(baseAmount * additive.productAmountPerMl);
};

export const getAdditiveAmount2 = (
	baseAmount: number,
	additive: Additive
): number =>
	roundToNearestDecimal(
		baseAmount * additive.productAmountPerMl,
		additive.increment
	);

/**
 * Get the total additive amount by summing up the additive amount for each additive.
 * Account for the displacement factor
 * Make sure to trim beyond the 4th decimal place
 */
export const getTotalAdditiveAmount = (
	baseAmount: number,
	additives: Additive[]
): number => {
	const totalAdditiveAmount = additives.reduce(
		(sum, additive) =>
			sum +
			getAdditiveAmount(baseAmount, additive) *
				additive.productDisplacementPerUnit,
		0
	);
	return totalAdditiveAmount;
};

export const getFinalVolume = (
	baseAmount: number,
	additives: Additive[],
	increment: number
): number => {
	const totalAdditiveAmount = additives.reduce(
		(sum, additive) =>
			sum +
			getAdditiveAmount(baseAmount, additive) *
				additive.productDisplacementPerUnit,
		0
	);
	return baseAmount + roundToNearestDecimal(totalAdditiveAmount, increment);
};

/**
 * Round to the nearest 4th decimal place
 */
export const roundToNearest4thDecimal = (num: number): number =>
	Math.round(num * 10000) / 10000;

/**
 * Round to the nearest decimal place based on the number of decimal places of
 * the increment.
 */
export const roundToNearestDecimal = (
	num: number,
	increment: number
): number => {
	const decimalPlaces = getDecimalPlaces(increment);
	// biome-ignore lint/style/useExponentiationOperator: clarity
	const multiplier = Math.pow(10, decimalPlaces);
	return Math.round(num * multiplier) / multiplier;
};

/**
 * Return the remainder of the total additive amount and the increment.
 * This is used to determine if the additive amount is divisible by the increment.
 */
export const getRemainder = (
	additiveAmount: number,
	increment: number
): number => {
	// biome-ignore lint/style/useExponentiationOperator: clarity
	const multiplier = Math.pow(10, getDecimalPlaces(increment));
	const a = additiveAmount * multiplier;
	const b = increment * multiplier;
	if (increment >= 1) {
		return a % b;
	}
	return Math.trunc(a % b) / multiplier;
};

export const hasRemainders = (
	additiveCandidates: AdditiveCandidate2[]
): boolean => {
	const remainders = additiveCandidates.map((additiveCandidate) =>
		getRemainder(
			additiveCandidate.additiveAmount,
			additiveCandidate.additive.increment
		)
	);
	// console.log(
	// 	`remainders: ${remainders}, base amount: ${additiveCandidates[0].baseAmount}`
	// );
	return !!remainders.find((r) => r !== 0);
};

/**
 * Return the number of zeros after the decimal point only if the number is less
 * than 1.
 */
export const getDecimalPlaces = (num = 0) => {
	// Convert the number to a string
	const numStr = num.toString();

	// Split the string at the decimal point
	const parts = numStr.split(".");

	// If there is no decimal point, return 0
	if (parts.length === 1) {
		return 0;
	}

	// Count the number of zeros after the decimal point
	let count = 0;
	// const zeros = /^0+/; // Regular expression to match leading zeros

	for (let i = 0; i < parts[1].length; i++) {
		const digit = parts[1][i];
		if (digit === "0") {
			count++;
		} else {
			break;
		}
	}

	return count + 1;
};

/**
 * @param volumeOnHand used to iterate based on base mL as whole numbers
 * @param scannedAdditives
 */
export const calculateVolumeOnHand = (
	volumeOnHand: number,
	scannedAdditives: Additive[]
): AdditiveCandidate2[] => {
	for (let baseAmount = volumeOnHand; baseAmount >= 1; baseAmount--) {
		const additiveCandidates: AdditiveCandidate2[] = scannedAdditives.map(
			(additive) => ({
				additive,
				additiveAmount: getAdditiveAmount(baseAmount, additive),
				baseAmount,
			})
		);

		// Establish a match
		if (!hasRemainders(additiveCandidates) && baseAmount > 0) {
			return additiveCandidates;
		}

		// Error if no match is found
		if (baseAmount === 1) {
			console.error(
				// biome-ignore lint/nursery/noSecrets: <explanation>
				"RecipeCalculatorModal.calculateVolumeOnHand: match not found"
			);
		}
	}
	return [];
};

export const calculateDesiredVolume = (
	desiredVolume: number,
	scannedAdditives: Additive[]
): AdditiveCandidate2[] => {
	for (let baseAmount = 1; baseAmount <= desiredVolume + 100; baseAmount++) {
		const additiveCandidates: AdditiveCandidate2[] = scannedAdditives.map(
			(additive) => ({
				additive,
				additiveAmount: getAdditiveAmount(baseAmount, additive),
				baseAmount,
			})
		);

		// Always round up to the nearest 0.01 for final volume
		const finalVolume = getFinalVolume(baseAmount, scannedAdditives, 0.01);

		// Establish a match
		if (
			!hasRemainders(additiveCandidates) &&
			finalVolume >= desiredVolume
		) {
			return additiveCandidates;
		}

		// Error if no match is found
		if (baseAmount === desiredVolume + 100) {
			console.error(
				// biome-ignore lint/nursery/noSecrets: <explanation>
				"RecipeCalculatorModal.calculateDesiredVolume: match not found"
			);
		}
	}
	return [];
};

/**
 * Return the minimum possible volume based on the additive increments.
 */
export const getMinimumPossibleVolume = (
	scannedAdditives: Additive[]
): number => {
	for (let baseAmount = 1; baseAmount <= 1000; baseAmount++) {
		const additiveCandidates: AdditiveCandidate2[] = scannedAdditives.map(
			(additive) => ({
				additive,
				additiveAmount: getAdditiveAmount(baseAmount, additive),
				baseAmount,
			})
		);

		if (!hasRemainders(additiveCandidates)) {
			return baseAmount;
		}

		if (baseAmount === 1000) {
			console.error(
				// biome-ignore lint/nursery/noSecrets: <explanation>
				"RecipeCalculatorModal.getMinimumPossibleVolume: match not found"
			);
		}
	}
	return -1;
};

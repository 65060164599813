import { VIRTUAL_PRINTER_ID } from "../app.constants";

export class PrinterModel {
	id: string;
	labelSize: string;
	manufacturer: string;
	model: string;
	name: string;
	networkRoute: string;
	ordinal: number;
	xOffset: number;
	yOffset: number;

	constructor(initial: IPrinter = {} as IPrinter) {
		if (!initial) {
			return;
		}

		Object.keys(initial).forEach((key) => {
			if (key == "isVirtual") {
				return;
			}

			this[key] = initial[key];
		});
	}

	get isVirtual() {
		return this.id.toString() === VIRTUAL_PRINTER_ID;
	}
}

export interface IPrinter {
	id: string;
	invert: boolean;
	labelSize: string;
	manufacturer: string;
	model: string;
	name: string;
	networkRoute: string;
	ordinal: number;
	xOffset: number;
	yOffset: number;
}

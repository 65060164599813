<ion-accordion-group
	*ngIf="milkBankProducts?.length"
	[multiple]="true"
	class="milk-info"
>
	<ion-accordion *ngFor="let p of milkBankProducts; let i = index">
		<ion-item class="milk-info__header" slot="header" [attr.data-testid]="'milkbankproduct_' + i">
			<ion-grid>
				<ion-row>
					<ion-col
						[ngClass]="getStatusColor(p)"
						size="auto"
					></ion-col>
					<ion-col>
						<ion-row>
							<ion-col
								class="milk-info__header__detail"
								size="auto"
							>
								<p class="milk-info__header__detail__label">
									bottle #
								</p>
								<p class="milk-info__header__detail__value">
									<span><b>{{ getBottleNumberText(p.bottleNumber)}}</b></span>
								</p>
							</ion-col>
							<ion-col size="auto">
								<div class="vertical-divider"></div>
							</ion-col>
							<ion-col
								class="milk-info__header__detail"
								size="auto"
							>
								<p class="milk-info__header__detail__label">
									State
								</p>
								<span class="milk-info__header__detail__state">
									<b [attr.data-testid]="'milkbankproduct_state_' + i">{{ p.productState }}</b>
								</span>
							</ion-col>
							<ion-col class="milk-info__header__chip" *ngIf="isExpirationVisible">
								<ion-chip
									[color]="
										expirationDateChipColor(
											p.expirationDate
										)
									"
								>
									<span
										[class]="{
											expired: isExpired(p.expirationDate)
										}"
										>{{
										getRelativeExpirationTime(
												p.expirationDate
											)
										}}
									</span>
								</ion-chip>
							</ion-col>
						</ion-row>
						<ion-row class="milk-info__secondary">
							<ion-col class="milk-info__secondary__contents" data-testid="milk-info__secondary__contents">
								Product:&nbsp;
								{{ p.name }}
							</ion-col>
						</ion-row>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-item>
		<ion-list slot="content">
			<ion-grid class="milk-info__content">
				<div class="milk-info__content__details">
					<app-milk-details
						[product]="p"
						[excludes]="[MilkDetailItem.PRODUCT_NAME]"
					></app-milk-details>
				</div>
				<ion-item *ngIf="hasButtons">
					<ion-button (click)="handleClickEdit(p)" size="default"
						>Edit</ion-button
					>
					<ion-button
						[disabled]="isExpired(p.expirationDate)"
						(click)="handleClickReprint(p)"
						size="default"
						>Reprint</ion-button
					>
				</ion-item>
			</ion-grid>
		</ion-list>
	</ion-accordion>
</ion-accordion-group>

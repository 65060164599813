import {
	Component,
	Input,
	OnInit,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import dayjs, { Dayjs } from 'dayjs';
import { MilkState } from 'src/app/app.enums';
import { MilkBottleModel } from 'src/app/models/milk.model';
import { getExpirationDuration } from 'src/app/utils/expiration.util';
import { isThawed } from 'src/app/utils/inventory.util';
import { getBottleNumberText } from 'src/app/utils/milk-label.util';
import { InlineDateComponent } from '../../components/inline-date/inline-date.component';
import { StorageService } from 'src/app/services';

@Component({
	selector: 'app-inline-thaw-milk',
	templateUrl: './inline-thaw-milk.modal.html',
	styleUrls: ['./inline-thaw-milk.modal.scss'],
})
export class InlineThawMilkModal implements OnInit {
	@ViewChildren(InlineDateComponent)
	inlineDateComponents: QueryList<InlineDateComponent>;

	@Input() title = 'Thaw Milk';
	@Input() header: string;
	@Input() message: string;
	@Input() doneText = 'Update';
	@Input() milkBottle: MilkBottleModel;
	@Input() hasVisibleBottleNumber = true;
	@Input() presetState: MilkState = MilkState.Thawed;

	isThawDateValid: boolean;
	disabled = false;

	getBottleNumberText = getBottleNumberText;

	get isThawedMilkState(): boolean {
		return isThawed(this.milkBottle);
	}

	get minDate(): Dayjs {
		const duration = getExpirationDuration({
			feedType: this.milkBottle.milkType,
			feedState: MilkState.Thawed,
			isFortified: false,
			contents: [],
			expirationPolicy: StorageService.expirationPolicy,
		});

		return dayjs().subtract(duration, 'hour');
	}

	get maxDate(): Dayjs {
		return dayjs().endOf('day');
	}

	constructor(private modalCtrl: ModalController) {}

	ngOnInit(): void {
		// set preset state
		if (this.presetState) {
			this.milkBottle.milkState = this.presetState;
		} else {
			throw Error('ngOnInit: No preset state found.');
		}
	}

	async back() {
		await this.modalCtrl.dismiss({
			milkBottle: null,
			milkBankProduct: null,
			action: 'cancel',
		} as InlineThawMilkModalOnDismiss);
	}

	async confirmDatePickerSelection() {
		this.inlineDateComponents.forEach((inlineDateComponent) =>
			inlineDateComponent.done(),
		);
	}

	handleValidity(value: boolean) {
		this.isThawDateValid = value;
	}

	async submit() {
		await this.confirmDatePickerSelection();

		await this.modalCtrl.dismiss({
			milkBottle: this.milkBottle,
			action: 'confirm',
		} as InlineThawMilkModalOnDismiss);
	}

	getThawedStateDate(milkBottle: MilkBottleModel): dayjs.Dayjs {
		if (isThawed(milkBottle) && milkBottle.thawedDate) {
			return dayjs(milkBottle.thawedDate);
		}

		return null;
	}

	setThawedDate(milkBottle: MilkBottleModel, date: dayjs.Dayjs) {
		if (isThawed(milkBottle)) {
			milkBottle.thawedDate = date;
		}
	}

	hasEditableStateDate(milkBottle: MilkBottleModel): boolean {
		return (
			milkBottle.milkState !== MilkState.Opened &&
			milkBottle.milkState !== MilkState.Stable
		);
	}

	isValid(isThawDateValid: boolean, milkBottle: MilkBottleModel): boolean {
		return isThawDateValid && isThawed(milkBottle) && !!milkBottle.thawedDate;
	}
}

export interface InlineThawMilkModalOnDismiss {
	milkBottle: MilkBottleModel;
	action: 'cancel' | 'confirm';
}

export interface InlineThawMilkModalHasDismissed {
	data: InlineThawMilkModalOnDismiss;
}

import { PrinterModel } from "../models/printer.model";

// biome-ignore lint/complexity/noStaticOnlyClass: factory
export class PrinterFactory {
	static create(data?): PrinterModel {
		const defaultObject = {
			id: "1",
			name: "Printer 1",
			ordinal: 1,
			networkRoute: "http://testprinter/api/v1/1/print/123",
			xOffset: 0,
			yOffset: 0,
			labelSize: "4x6",
			model: "Zebra",
			manufacturer: "Zebra",
		};
		return new PrinterModel({ ...defaultObject, ...data });
	}
}

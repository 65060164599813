import { Injectable } from "@angular/core";
import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from "@angular/common/http";
import { lastValueFrom, Observable, throwError } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import { datadogLogs } from "@datadog/browser-logs";

import { environment } from "src/environments/environment";
import { WhoIsModel } from "src/app/models/whois.model";
import {
	AppConfigs,
	ConfigModel,
	IAppConfigs,
} from "src/app/models/config.model";

import { StorageService } from "src/app/services/storage.service";
import { RestApiService } from "src/app/services/rest-api.service";
import { PrinterModel } from "../models/printer.model";
import { IUserProfile, UserProfileModel } from "../models/user-profile.model";
import buildNumber from "../../environments/build-number.json";
import { AlertController } from "@ionic/angular";
import { TenantInfo } from "../models/tenant-configs.model";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	headers: HttpHeaders;

	constructor(
		private httpClient: HttpClient,
		private http: RestApiService,
		private alertCtrl: AlertController
	) {
		/*
			https://angular.io/guide/http#setup
		*/
		this.headers = new HttpHeaders({
			Accept: "*/*",
			"Content-Type": "application/json",
		});
	}

	// biome-ignore lint/suspicious/noExplicitAny: Http call
	get(url: string): Observable<any> {
		return this.httpClient
			.get(url, { headers: this.headers })
			.pipe(
				timeout(
					StorageService.appConfigs?.timeoutDurationInMilliSeconds
				),
				catchError(this.handleError)
			);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Http call
	post(url: string, payload: any): Observable<any> {
		return this.httpClient
			.post(url, payload, { headers: this.headers })
			.pipe(
				timeout(
					StorageService.appConfigs?.timeoutDurationInMilliSeconds
				),
				catchError(this.handleError)
			);
	}

	login(credentials: AuthCredentials): Promise<AuthToken> {
		const url = `${environment.apiUrl}/auth/token`;
		return lastValueFrom(this.post(url, credentials));
	}

	loginByAuthKey(authKey: string): Promise<AuthToken> {
		const url = `${environment.apiUrl}/auth/app-auth`;
		const body: IAuthKey = { authKey };
		return lastValueFrom(this.post(url, body));
	}

	loginByClerkToken(clerkToken: string): Promise<AuthToken> {
		const url = `${environment.apiUrl}/auth/clerk-auth`;
		const body: ClerkToken = { token: clerkToken };
		return lastValueFrom(this.post(url, body));
	}

	/**
	 * Only call once logged in
	 */
	getUserProfile(): Promise<UserProfileModel> {
		const url = `${environment.apiUrl}/profile`;
		return lastValueFrom(
			this.get(url).pipe(
				map((res: IUserProfile) => new UserProfileModel(res))
			)
		);
	}

	updateUserProfile(payload: {
		currentPrinterId?: PrinterModel["id"];
		preferredTenantId?: TenantInfo["id"];
	}): Promise<UserProfileModel> {
		const url = `${environment.apiUrl}/users/profile`;

		return lastValueFrom(
			this.httpClient
				.put(url, payload, { headers: this.headers })
				.pipe(map((res: IUserProfile) => new UserProfileModel(res)))
		);
	}

	/**
	 *
	 * @param credentials takes in an AuthCredentials object or an authKey string
	 * @param options
	 */
	async authenticateUser(
		credentials: AuthCredentials | string,
		options?: { isAdminPortal: boolean }
	) {
		const isAdminPortal = options?.isAdminPortal ?? false;
		if (isAdminPortal) {
			StorageService.authToken = await this.loginByAuthKey(
				credentials as string
			);
		} else {
			StorageService.authToken = await this.login(
				credentials as AuthCredentials
			);
			datadogLogs.setGlobalContextProperty(
				"username",
				(credentials as AuthCredentials).username
			);
			datadogLogs.logger.info(
				`User successfully logged in: ${
					(credentials as AuthCredentials).username
				}`
			);
		}
	}

	/**
	 *
	 * @param clerkToken takes in an token received from clerk
	 */
	async authenticateUserWithClerk(
		clerkToken: string,
		options?: { isAdminPortal: boolean }
	) {
		const isAdminPortal = options?.isAdminPortal ?? false;
		if (isAdminPortal) {
			StorageService.authToken = await this.loginByAuthKey(
				clerkToken as string
			);
		} else {
			//
			StorageService.authToken = await this.loginByClerkToken(
				clerkToken as string
			);
			// datadogLogs.setGlobalContextProperty(
			// 	"username",
			// 	(clerkToken as string).username
			// );
			// datadogLogs.logger.info(
			// 	`User successfully logged in: ${
			// 		(credentials as string).username
			// 	}`
			// );
		}
	}

	/**
	 * TODO: login page logs the entered nurse id (username) via {@link authenticateUser}
	 *  and when getting the user profile, it logs the email address while this workflow
	 *  logs the "loginName" property. We should standardize on one or the other.
	 */
	async setUserProfile(options?: { isAdminPortal: boolean }) {
		const isAdminPortal = options?.isAdminPortal ?? false;
		StorageService.user = await this.getUserProfile();

		// If nurse id was not set, then show error
		if (!StorageService.user.nurseId) {
			throw Error("Nurse ID not set");
		}

		if (isAdminPortal) {
			datadogLogs.setGlobalContextProperty(
				"username",
				StorageService.user.loginName
			);
			datadogLogs.logger.info(
				`user successfully logged in via MAP: ${StorageService.user.loginName}`
			);
		} else {
			datadogLogs.logger.info(
				`set user data for ${StorageService.user.email}`,
				{
					authorizedTenants: StorageService.user.authorizedTenants,
				}
			);
		}
	}

	/**
	 * Get another user's tenant permissions.
	 * TODO: why is there observe: "response"?
	 * @returns null if the user wasn't found
	 */
	whois(params: {
		secondaryId?: string;
		username?: string;
	}): Promise<WhoIsModel | null> {
		const url = `${environment.apiUrl}/auth/whois`;
		return lastValueFrom(
			this.httpClient
				.get(url, {
					headers: this.headers,
					params,
					observe: "response",
				})
				.pipe(
					timeout(
						StorageService.appConfigs.timeoutDurationInMilliSeconds
					),
					catchError(this.handleError),
					map((res) => {
						if (res.status === 204) {
							return null;
						}
						return new WhoIsModel(res.body);
					})
				)
		);
	}

	async getTenantConfigs(id: string): Promise<ConfigModel> {
		const url = `${environment.apiUrl}/tenants/${id}`;
		return lastValueFrom(
			this.get(url).pipe(map((res) => new ConfigModel(res)))
		);
	}

	/**
	 * Clear storage including auth tokens, facility configs, and user profile.
	 * Unsubscribe all from all Observables and Services. This ensures that any Observables that are subscribed to
	 * in the background are unsubscribed from to prevent potential memory leaks.
	 *
	 * Active tenant cannot be cleared because that is where permissions are derived from, even on the login page.
	 * I'm not exactly sure why, but the login page currently won't load if this is cleared.
	 *
	 * Saved tenants are not cleared because they are designed to remain persistent. They keep track of the last used
	 * tenant for that device.
	 *
	 * The last used cached printer is not cleared because if a new user logs in for the first time, they will
	 * by default use the last used cached printer. This is to prevent the user from having to select a printer.
	 *
	 * App configs are not cleared because they are used to determine the version, build, and timeout duration.
	 */
	logout(keepSubscriptionsActive?: boolean): Promise<boolean> {
		console.log("Logging out...");
		StorageService.user = null;
		StorageService.authToken = null;
		StorageService.configs = null;
		StorageService.printPreview = null;
		StorageService.preferredTenant = null;
		if (!keepSubscriptionsActive) {
			StorageService.unsubscribeAll();
		}
		return Promise.resolve(true);
	}

	/**
	 * The one quirk with this is that since timeout duration is pulled from
	 * the server, we have to set a default one in the environment file.
	 *
	 * Since timeout duration is set by the app configs, we have to get the app
	 * configs using HttpClient and not RestApiService.
	 */
	getAppConfigs(): Promise<IAppConfigs> {
		const url = `${environment.apiUrl}/version`;
		return lastValueFrom(
			this.httpClient
				.get(url)
				.pipe(timeout(15000), catchError(this.handleError))
		);
	}

	async getAppConfigWithFallback(): Promise<AppConfigs> {
		const local = {
			tenant: null, // TODO: we don't really have this info but we can extrapolate it from the url
			environment: environment.type,
			appVersion: buildNumber.VERSION,
			appBuildNumber: buildNumber.GIT_SHA.substring(0, 7),
			dbVersion: null, // we don't have this info
			dbLastUpdated: null, // we don't have this info
			production: environment.production,
			sessionLengthInMinutes: environment.settings.sessionLengthMinutes,
			timeoutDurationInMilliSeconds: environment.settings.timeoutDuration,
			enableBadgeScanning: environment.settings.enableBadgeScanning,
			enableSelectMilk: environment.settings.enableSelectMilk,
			authGuard: environment.settings.authGuard,
			bypassExpiration: environment.settings.bypassExpiration,
			bypassOrderMatching: environment.settings.bypassOrderMatching,
		} as IAppConfigs;
		try {
			const server = await this.getAppConfigs();

			return new AppConfigs({
				...local,
				...server,
			});
		} catch (e) {
			return new AppConfigs(local);
		}
	}

	// biome-ignore lint/suspicious/noExplicitAny: http response?
	private handleError(error: HttpErrorResponse): Observable<any> {
		if (error.status === 500) {
			alert("Internal Server Error. Please contact support.");
			return;
		}
		return throwError(() => error);
	}
}

// TODO: ML-1292 - Prefix all interfaces with I
export interface AuthCredentials {
	username: string;
	password: string;
}

export interface IAuthKey {
	authKey: string;
}

export interface AuthToken {
	token: string;
}

export interface ClerkToken {
	token: string;
}
